import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  video: {},
  files: {},
  id: {},
};

const slice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setVideo: (state, action) => {
      state.video = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setID: (state, action) => {
      state.id = action.payload;
    },
    resetVideo: (state, action) => {
      state = initialState;
    },
  },
});

export const { setVideo, resetVideo, setFiles, setID } = slice.actions;
export default slice.reducer;
