import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import GetStorage from "../services/Storage";
import http from "../services/http";
import polygon from "../assets/images/Polygon 56.png";
import { SpinnerCircular } from "spinners-react";
import Dropdown from "react-bootstrap/Dropdown";
import dots from "../assets/images/dots.svg";
import { Table, Space, Spin } from 'antd'
import { showToast } from "./ShowToast";

export default function SelecctAnEvo() {
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [packages, setPackage] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    Packagess();
    localStorage.removeItem("viewed");
    localStorage.removeItem("url");
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Delivery Time',
      dataIndex: 'delivery_time',
      key: 'delivery_time',
      render: (_, record) => (
        <div> Delivered within {record.delivery_time} days</div>


      ),
      sorter: (a, b) => a.delivery_time.length - b.delivery_time.length,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => (
        <div>${record.price}.00</div>


      ),
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, item) => (
        <table>
          <td>
            <div className="btn_wraper d-flex justify-content-left">
              <button
                type="button"
                className="them_btn "
                onClick={() => Packagesdata(item)}
              >
                Select Package
              </button>
              <button
                type="button"
                className="them_btn "
                onClick={() =>
                  WatchVideoCode(item.id, "package")
                }
              >
                Show video
              </button>
            </div>

          </td>

        </table>
      ),
    },
  ]
  const Packagess = async () => {
    setLoading(true);

    http
      .get(`api/common/packages?token=${token}`)
      .then((res) => {
        console.log("packages  ------->>>>", res.data);
        setPackage(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const Packagesdata = (item) => {
    localStorage.setItem("packagesID", JSON.stringify(item));
    navigate("/evoPackagesFiveMMint");
  };

  const getVideoToken = async (id, type, code) => {
    setLoading(true);
    http
      .post(`api/common/video_token`, {
        code: code,
        token: token,
      })
      .then((res) => {
        GetVideo(res.data.video_token, id, type);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  }
  const GetVideo = async (video_token, id, type) => {
    http
      .post(`api/common/get_video?token=${token}`, {
        video_token: video_token,
        type: type,
        id: id,
      })
      .then((res) => {
        setLoading(false);

        localStorage.setItem("url", JSON.stringify(res.data));
        navigate("/logsVideo");
        console.log("navigate ------->>>>");
      })
      .catch((error) => {

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const WatchVideoCode = async (id, type) => {
    setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        const code = res.data.code;
        getVideoToken(id, type, code);
        //navigate(`/otpLogs/${id}/${type}`);
        // setLocker(res.data);
        //setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification">
          <div className="login_inner custom_hight">
            <h1 className="mb_30 main_title ">Select an evo Package</h1>
            <p className="mb_40 them_para text-center mx-auto them_para_2 ">
              Select from the following eVO choices to go with the video clip
              you selected.
            </p>
            {
              loading ? (<Space size="middle">
                <Spin size="large" />
              </Space>) : <div className="custom_container mb_40 over_flow">
                <div className="table_vedio_upload  mt_40 mb_30 over_flow mb-0  ">

                  <Table columns={columns} dataSource={packages}></Table>
                </div>
              </div>
            }
          </div>
        </section>
      </body>
    </>
  );
}
