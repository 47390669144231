import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inputvalue: "",
  shareToken: {},
  video: {},
};

const slice = createSlice({
  name: "input",
  initialState,
  reducers: {
    setInput: (state, action) => {
      state.inputvalue = action.payload;
    },
    setShareToken: (state, action) => {
      state.shareToken = action.payload;
    },
    setVideo: (state, action) => {
      state.video = action.payload;
    },
    resetInput: (state, action) => {
      state = initialState;
    },
  },
});

export const { setInput, resetInput, setShareToken, setVideo } = slice.actions;
export default slice.reducer;
