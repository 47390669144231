import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Video() {
  const location = useLocation();
  const { name } = location.state;

  const navigate = useNavigate();

  return (
    <>
      <svg
        onClick={() => navigate(-1)}
        style={{
          color: "white",
          position: "absolute",
          width: 20,
          height: 15,
          marginLeft: 20,
          marginTop: 20,
          zIndex: 1,
        }}
        viewBox="4 4 33.583 27.008"
      >
        <path
          id="Path_83491"
          d="M 37.58322906494141 15.81591987609863 L 10.46499538421631 15.81591987609863 L 19.90085029602051 6.380063056945801 L 17.50390815734863 3.999999761581421 L 3.999999761581421 17.50390815734863 L 17.50390815734863 31.0078125 L 19.88397026062012 28.62774848937988 L 10.46499538421631 19.19189453125 L 37.58322906494141 19.19189453125 L 37.58322906494141 15.81591987609863 Z"
        ></path>
      </svg>

      <div
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <video
          controls
          width={"100%"}
          height={"100%"}
          controlsList={"nodownload"}
          autoPlay
        >
          <source src={name} />
        </video>
      </div>
    </>
  );
}

export default Video;
