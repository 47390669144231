import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cv: {},
};

const slice = createSlice({
  name: "cvsss",
  initialState,
  reducers: {
    setCv: (state, action) => {
      state.cv = action.payload;
    },
    resetCv: (state, action) => {
      state = initialState;
    },
  },
});

export const { setCv, resetCv } = slice.actions;
export default slice.reducer;
