import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { Link, useNavigate } from "react-router-dom";
import polygon from "../assets/images/Polygon 56.png";
import Header from "./Header";
import Modal from "react-bootstrap/Modal";
import ReactStars from "react-rating-stars-component";
import { SpinnerCircular } from "spinners-react";
import { showToast } from "./ShowToast";
import { ToastContainer } from "react-toastify";
import dots from "../assets/images/dots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "antd";

export default function EvoOrder() {
  const [showModal, setShow] = useState(false);
  const [id, setId] = useState(0);
  const [uploadSpin, setUploadSpin] = useState(false);

  const handleShow = (item) => {
    setId(item);
    setShow(true);
  };

  const token = GetStorage.get("xAuthToken");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [key, setKey] = useState("home");
  const [deleivered, setDelivered] = useState([]);
  const [pending, setPending] = useState([]);
  const [videoquality, setVideoquality] = useState(0);
  const [speachquality, setSpeachquality] = useState(0);
  const [recomand, setReecomand] = useState(0);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    EvoOrders();
    localStorage.removeItem("viewed");
    localStorage.removeItem("url");
    setError("");
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstCameraOutput',
      key: 'firstCameraOutput',
      render:(_,record)=>(
        <div>
           {
                                      record.firstCameraOutput.split("/")[
                                        record.firstCameraOutput.split("/")
                                          .length - 1
                                      ]
                                    }
                                  
        </div>
      ),
      sorter: (a, b) => a.firstCameraOutput.length - b.firstCameraOutput.length,
    },
    {
      title: 'eVO Package',
      dataIndex: 'package',
      key: 'package',
      sorter: (a, b) => a.package.length - b.package.length,
    },
    {
      title: 'Delivery Time',
      dataIndex: 'delivery_time',
      key: 'delivery_time',
      render: (_, record) => (
       <div> {record.delivery_time} days</div>
       

      ),
      sorter: (a, b) => a.delivery_time.length - b.delivery_time.length,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => (
       <div>${record.price}.20</div>
       

      ),
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, item) => (
        <table>
          <td>
          <div class="btn_wraper d-flex justify-content-left  ">
                                      <button
                                        type="button"
                                        class="them_btn_Unsubscribed   "
                                      >
                                        Pending
                                      </button>
                                      <button
                                        type="button"
                                        className="them_btn "
                                        onClick={() =>
                                          WatchVideoCode(
                                            item.id,
                                            "order_pending"
                                          )
                                        }
                                      >
                                        Show video
                                      </button>
                                    </div>
               
          </td>
         
        </table>
      ),
    },
  ]

  const columns2 = [
    {
      title: 'Name',
      dataIndex: 'evo_path',
      key: 'evo_path',
      render:(_,record)=>(
        <div>
           {
                                      record.evo_path.split("/")[
                                        record.evo_path.split("/")
                                          .length - 1
                                      ]
                                    }
                                  
        </div>
      ),
      sorter: (a, b) => a.evo_path.length - b.evo_path.length,
    },
    {
      title: 'eVO Package',
      dataIndex: 'package',
      key: 'package',
      sorter: (a, b) => a.package.length - b.package.length,
    },
    {
      title: 'Delivery Time',
      dataIndex: 'delivery_time',
      key: 'delivery_time',
      render: (_, record) => (
       <div> {record.delivery_time} days</div>
       

      ),
      sorter: (a, b) => a.delivery_time.length - b.delivery_time.length,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => (
       <div>${record.price}.20</div>
       

      ),
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, item) => (
        <table>
          <td>
          <div class="btn_wraper d-flex justify-content-left    ">
                                    <button
                                      type="button"
                                      class="them_btn "
                                      data-toggle="modal"
                                      data-target="#starrating"
                                      onClick={() => handleShow(item.id)}
                                    >
                                      evo Feedback
                                    </button>
                                    <button
                                      type="button"
                                      className="them_btn "
                                      onClick={() =>
                                        WatchVideoDelivered(item.id, "evo")
                                      }
                                    >
                                      Show video
                                    </button>
                                  </div>
               
          </td>
         
        </table>
      ),
    },

  ]

  const handleClose = () => {
    setShow(false);
    setError("");
    setMessage("");
    setVideoquality(0);
    setSpeachquality(0);
    setReecomand(0);
    setInputText("");
  };

  const InProgress = (k) => {
    setKey(k);
    switch (k) {
      case "home":
        http
          .get(`api/parent/orders/pending?token=${token}`)
          .then((res) => {
            console.log("panding=========>", res.data);
            setPending(res.data);
          })
          .catch((error) => {
            console.log("error==>", error);
          });
        break;
      case "profile":
        http
          .get(`api/parent/orders/delivered?token=${token}`)
          .then((res) => {
            console.log("deleivered=====>", res.data);
            setDelivered(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      default:
        http
          .get(`api/parent/orders/pending?token=${token}`)
          .then((res) => {
            console.log("available=====>", res.data);
            setPending(res.data);
          })
          .catch((error) => {
            console.log("error==>", error);
          });
        break;
    }
  };

  const EvoOrders = async () => {
    setLoading(true);

    http
      .get(`api/parent/orders/pending?token=${token}`)
      .then((res) => {
        console.log("evo order  pending------->>>>", res.data);
        setPending(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
              console.log("error ------->>>>", error.response.data.errors);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const ratingChanged = (videoquality) => {
    setVideoquality(videoquality);
  };
  const ratingChangeds = (speachquality) => {
    setSpeachquality(speachquality);
  };

  const RecommendsUs = (recomand) => {
    setReecomand(recomand);
  };
  const handleSubmit = async (e) => {
    setUploadSpin(true);

    http
      .post(`api/parent/feedback?token=${token}`, {
        comment: inputText,
        helfpul_stars: speachquality,
        recommendus_stars: recomand,
        service_stars: videoquality,
        order_id: id,
      })
      .then((res) => {
        console.log("response ------->>>>", res.data);
        setUploadSpin(false);
        setError("");
        setMessage(res.data.message);

        handleClose();
        showToast("success", `${res.data.message} your feedback.`);
      })
      .catch((error) => {
        setUploadSpin(false);
        setMessage("");
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const WatchVideoCode = async (id, type) => {
    // setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        console.log("response ------->>>>", res.data);

        navigate(`/otpLogs/${id}/${type}`);

        // setLocker(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const WatchVideoDelivered = async (id, type) => {
    // setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        console.log("response ------->>>>", res.data);

        navigate(`/otpLogs/${id}/${type}`);

        // setLocker(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  return (
    <>
      <Header />

      <section className="otp_verification">
        <div className="login_inner custom_hight">
          <h1 className="mb_30 main_title ">eVO Order(s)</h1>
          <div className="user_profile_eidte">
            <p style={{ color: "red" }}>{error}</p>
          </div>
          <div className="personal_and_bank_info mb_40 Order_table">
            <Tabs
              onSelect={(k) => InProgress(k)}
              activeKey={key}
              style={{ margin: "auto" }}
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title=" Pending">
                <div className="container" style={{ maxWidth: "100%" }}>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-Aprove"
                      role="tabpanel"
                      aria-labelledby="pills-Aprove-tab"
                    >
                      <div class="table_vedio_upload   container mt_40 mb_30 over_flow mb-0 ">
                        
                        <Table className="antd_order_table" columns={columns} dataSource={pending}></Table>
                      </div>
                      <div class="btn_wraper">
                        <button
                          type="button"
                          class="them_btn_ouline mt_40 mb_30"
                          onClick={() => navigate("/unSubscrbeTable")}
                        >
                          Back to Swimmer Locker
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="profile" title=" Delivered" className="ml">
                <div className="container" style={{ maxWidth: "100%" }}>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-Aprove"
                      role="tabpanel"
                      aria-labelledby="pills-Aprove-tab"
                    >
                      <div class="table_vedio_upload   container mt_40 mb_30 over_flow mb-0 ">
                        {/* <table class="table table-borderless table-responsive-lg">
                          <thead>
                            <tr>
                              {/* <th scope="col" class="  pl_25">
                              Video
                            </th> 
                              <th
                                scope="col"
                                class=" "
                                style={{ paddingLeft: "2%" }}
                              >
                                Name
                              </th>
                              <th scope="col" class=" ">
                                eVO Package
                              </th>
                              <th scope="col" class=" ">
                                Delivery Time
                              </th>
                              <th scope="col" class=" ">
                                Price
                              </th>
                              <th scope="col" class=" text-center">
                                Order Status
                              </th>
                              <th scope="col" class="  pl_25"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {deleivered.map((item, key) => (
                              <tr>
                                {/* <td class="pl_25">
                                <Link
                                  to="/shareVideos"
                                  state={{
                                    id: item.id,
                                    name: item.evo_path,
                                  }}
                                >
                                  <video width={100} height={70}>
                                    <source src={item.evo_path} />
                                  </video>
                                  <img
                                    src={polygon}
                                    alt=""
                                    style={{
                                      position: "absolute",
                                      marginTop: 25,
                                      marginLeft: -60,
                                      width: 20,
                                      height: 20,
                                    }}
                                  />
                                </Link>
                              </td> 
                                <td style={{ paddingLeft: "2%" }}>
                                  {
                                    item.evo_path.split("/")[
                                      item.evo_path.split("/").length - 1
                                    ]
                                  }
                                </td>
                                <td>{item.package}</td>
                                <td>{item.delivery_time} days</td>
                                <td>${item.price}.20</td>
                                <td>
                                  <div class="btn_wraper d-flex justify-content-center    ">
                                    <button
                                      type="button"
                                      class="them_btn "
                                      data-toggle="modal"
                                      data-target="#starrating"
                                      onClick={() => handleShow(item.id)}
                                    >
                                      evo Feedback
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className="btn_wraper d-flex justify-content-center">
                                    <button
                                      type="button"
                                      className="them_btn "
                                      onClick={() =>
                                        WatchVideoDelivered(item.id, "evo")
                                      }
                                    >
                                      Show video
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                        <Table className="antd_order_table" columns={columns2} dataSource={deleivered}></Table>
                      </div>
                      <div class="btn_wraper">
                        <button
                          type="button"
                          class="them_btn_ouline mt_40 mb_30"
                          onClick={() => navigate("/unSubscrbeTable")}
                        >
                          Back to Swimmer Locker
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      <Modal className="starrating" show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title as="h5">eVO Feedback</Modal.Title>
          <button
            onClick={handleClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">&times;</span>{" "}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="raing_star">
            <p>Service up to mark</p>
            <div className="rates" style={{ marginLeft: 318 }}>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={30}
                activeColor="#ffd700"
              />
            </div>
          </div>
          <div className="raing_star">
            <p>Recommend us</p>
            <div className="rates" style={{ marginLeft: 318 }}>
              <ReactStars
                count={5}
                onChange={RecommendsUs}
                size={30}
                activeColor="#ffd700"
              />
            </div>
          </div>
          <div className="raing_star">
            <p>How helpful was this</p>
            <div className="rates" style={{ marginLeft: 318 }}>
              <ReactStars
                count={5}
                onChange={ratingChangeds}
                size={30}
                activeColor="#ffd700"
              />
            </div>
          </div>

          <div class="form-group custom_text_area">
            <textarea
              class="form-control"
              placeholder="Give us any feedback"
              rows="5"
              style={{ height: 150 }}
              onChange={(e) => {
                setInputText(e.target.value);
              }}
            ></textarea>
          </div>

          <h6 style={{ color: "red" }}>{error}</h6>
          <h6 style={{ color: "green" }}>{message}</h6>
          <div className="modal-footer p0">
            <button
              type="button"
              className="them_btn_ouline ml1"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="them_btn ml"
              onClick={handleSubmit}
            >
              {uploadSpin ? (
                <SpinnerCircular className="button-spin" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}
