import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/assets/css/style.css";
import "../src/assets/css/bootstrap.css";
import "../src/assets/css/responsive.css";

import Login from "./components/Login";
import OtpVerification from "./components/OtpVerification";
import CnfirmIdentity from "./components/CnfirmIdentity";
import OtpNumber from "./components/OtpNumber";
import WelcomeJessica from "./components/WelcomeJessica";
import SelectGroup from "./components/SelectGroup";
import WelcomeJesscaTwo from "./components/WelcomeJesscaTwo";
import Subscribe from "./components/Subscribe";
import EducationalVoiceOvers from "./components/EducationalVoiceOvers";
import Video from "./components/Video";
import UnSubscribe from "./components/UnSubscribe";
import UnSubscrbeTable from "./components/UnSubscrbeTable";
import SelecctAnEvo from "./components/SelecctAnEvo";
import EvoPackagesFiveMMint from "./components/EvoPackagesFiveMMint";
import EvoOrderSummery from "./components/EvoOrderSummery";
import CheckOut from "./components/CheckOut";
import PaymentProcessStripe from "./components/PaymentProcessStripe";
import ThankYouForYourOrder from "./components/ThankYouForYourOrder";
import EvoOrder from "./components/EvoOrder";
import SubscribeYourSwimmer from "./components/SubscribeYourSwimmer";
import PayLater from "./components/PayLater";
import PayNow from "./components/PayNow";
import SubscriptionComplete from "./components/SubscriptionComplete";
import ManageSubscription from "./components/ManageSubscription";
import PaymentHistory from "./components/PaymentHistory";
import ManageCurrentSwimmers from "./components/ManageCurrentSwimmers";
import CancelAllSubscriptions from "./components/CancelAllSubscriptions";
import SubscriptionCancelledSuccessfully from "./components/SubscriptionCancelledSuccessfully";
import SubscriptionUpdatedSuccessfully from "./components/SubscriptionUpdatedSuccessfully";
import WeAreSorryToSeeYouGo from "./components/WeAreSorryToSeeYouGo";
import SwimmerParentFAQ from "./components/SwimmerParentFAQ";
import SwimClubFAQ from "./components/SwimClubFAQ";
import SelectGroupChild from "./components/SelectGroupChild";
import ManageCurrentSwimmersSubUn from "./components/ManageCurrentSwimmersSubUn";
import ManageCurrentSwimmersUpdate from "./components/ManageCurrentSwimmersUpdate";
import ShareVideos from "./components/ShareVideos";
import ShareOtp from "./components/ShareOtp";
import LearnMoreAboutEVO from "./components/LearnMoreAboutEVO";
import StripePayment from "./components/StripePayment";
import CheckoutForm from "./components/CheckoutForm.js";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./components/NotFound";
import Redirect from "./components/Redirect";
import OtpLogs from "./components/OtpLogs";
import LogsVideo from "./components/LogsVideo";
import ForgetPassword from "./components/ForgetPassword";
import Resetpassword from "./components/ResetPassword";
import VerifyYourEmail from "./components/VerifyYourEmail";
import ShareVideosVoice from "./components/ShareVideosVoice";
import Strockes from "./components/Strockes";
import VideosByStrock from "./components/VideosByStrock";
import SwimmerLocker from "./components/SwimmerLocker";
import ShareVideosVoiceSwimmer from "./components/ShareVideosVoiceSwimmer";
import ManageParents from "./components/ManageParents";
import AddParent from "./components/AddParent";
import Profile from "./components/Profile";
import Register from "./components/Register";
import AddSwimmer from "./components/AddSwimmer";
import ManageSwimmers from "./components/ManageSwimmers";

function App() {

  let user = localStorage.getItem("user");

  user = JSON.parse(user);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Redirect>
              <Login />
            </Redirect>
          }
        />
        <Route
          path="/register"
          element={
            <Register />
          }
        />
        <Route
          path="/otpVerificaton"
          element={
            <ProtectedRoute>
              <Redirect>
                <OtpVerification />
              </Redirect>
            </ProtectedRoute>
          }
        />
        <Route path="/cnfirmIdentity" element={<CnfirmIdentity />} />

        <Route
          path="/otpNumber"
          element={
            <ProtectedRoute>
              <OtpNumber />
            </ProtectedRoute>
          }
        />

        <Route
          path="/welcomeJessica"
          element={
            <ProtectedRoute>
              <WelcomeJessica />
            </ProtectedRoute>
          }
        />
        <Route
          path="/selectGroup"
          element={
            <ProtectedRoute>
              <SelectGroup />
            </ProtectedRoute>
          }
        />
        <Route
          path="/welcomeJesscaTwo"
          element={
            <ProtectedRoute>
              <WelcomeJesscaTwo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscribe"
          element={
            <ProtectedRoute>
              <Subscribe />
            </ProtectedRoute>
          }
        />
        <Route
          path="/educationalVoiceOvers"
          element={
            <ProtectedRoute>
              <EducationalVoiceOvers />
            </ProtectedRoute>
          }
        />

        <Route
          path="/video"
          element={
            <ProtectedRoute>
              <Video />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shareVideos"
          element={
            <ProtectedRoute>
              <ShareVideos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shareOtp"
          element={
            <ProtectedRoute>
              <ShareOtp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unSubscribe"
          element={
            <ProtectedRoute>
              <UnSubscribe />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkoutForm"
          element={
            <ProtectedRoute>
              <CheckoutForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stripePayment"
          element={
            <ProtectedRoute>
              <StripePayment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/learnMoreAboutEVO"
          element={
            <ProtectedRoute>
              <LearnMoreAboutEVO />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unSubscrbeTable"
          element={
            <ProtectedRoute>
              <UnSubscrbeTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/evoPackagesFiveMMint"
          element={
            <ProtectedRoute>
              <EvoPackagesFiveMMint />
            </ProtectedRoute>
          }
        />
        <Route
          path="/evoOrderSummery"
          element={
            <ProtectedRoute>
              <EvoOrderSummery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/selecctAnEvo"
          element={
            <ProtectedRoute>
              <SelecctAnEvo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscribeYourSwimmer"
          element={
            <ProtectedRoute>
              <SubscribeYourSwimmer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkOut"
          element={
            <ProtectedRoute>
              <CheckOut />
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentProcessStripe"
          element={
            <ProtectedRoute>
              <PaymentProcessStripe />
            </ProtectedRoute>
          }
        />
        <Route
          path="/thankYouForYourOrder"
          element={
            <ProtectedRoute>
              <ThankYouForYourOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/evoOrder"
          element={
            <ProtectedRoute>
              <EvoOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payLater"
          element={
            <ProtectedRoute>
              <PayLater />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payNow"
          element={
            <ProtectedRoute>
              <PayNow />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptionComplete"
          element={
            <ProtectedRoute>
              <SubscriptionComplete />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageSubscription"
          element={
            <ProtectedRoute>
              <ManageSubscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentHistory"
          element={
            <ProtectedRoute>
              <PaymentHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageCurrentSwimmers"
          element={
            <ProtectedRoute>
              <ManageCurrentSwimmers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cancelAllSubscriptions"
          element={
            <ProtectedRoute>
              <CancelAllSubscriptions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptionCancelledSuccessfully"
          element={
            <ProtectedRoute>
              <SubscriptionCancelledSuccessfully />
            </ProtectedRoute>
          }
        />

        <Route
          path="/subscriptionUpdatedSuccessfully"
          element={
            <ProtectedRoute>
              <SubscriptionUpdatedSuccessfully />
            </ProtectedRoute>
          }
        />
        <Route
          path="/weAreSorryToSeeYouGo"
          element={
            <ProtectedRoute>
              <WeAreSorryToSeeYouGo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageparents"
          element={
            <ProtectedRoute>
              <ManageParents />
            </ProtectedRoute>
          }
        />

        {/* {user && !user.data?.mainparentphone &&  */}
        <Route
          path="/manageswimmers"
          element={
            <ProtectedRoute>
              <ManageSwimmers />
            </ProtectedRoute>
          }
        />
        {/* } */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/addparent"
          element={
            <ProtectedRoute>
              <AddParent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addswimmer"
          element={
            <ProtectedRoute>
              <AddSwimmer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/swimmerParentFAQ"
          element={
            <ProtectedRoute>
              <SwimmerParentFAQ />
            </ProtectedRoute>
          }
        />
        <Route
          path="/swimClubFAQ"
          element={
            <ProtectedRoute>
              <SwimClubFAQ />
            </ProtectedRoute>
          }
        />
        <Route
          path="/selectGroupChild"
          element={
            <ProtectedRoute>
              <SelectGroupChild />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageCurrentSwimmersSubUn"
          element={
            <ProtectedRoute>
              <ManageCurrentSwimmersSubUn />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageCurrentSwimmersUpdate"
          element={
            <ProtectedRoute>
              <ManageCurrentSwimmersUpdate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/otpLogs/:id/:type"
          element={
            <ProtectedRoute>
              <OtpLogs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logsVideo"
          element={
            <ProtectedRoute>
              <LogsVideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shareVideosVoice"
          element={
            <ProtectedRoute>
              <ShareVideosVoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/strockes"
          element={
            <ProtectedRoute>
              <Strockes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/videosByStrock/:id"
          element={
            <ProtectedRoute>
              <VideosByStrock />
            </ProtectedRoute>
          }
        />

        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/verifyYourEmail" element={<VerifyYourEmail />} />
        <Route path="/:parentid/swimmerslocker" element={<SwimmerLocker />} />
        <Route
          path="/shareVideosVoiceSwimmer"
          element={
            <ShareVideosVoiceSwimmer />
          }
        />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
