import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

export default function CancelAllSubscriptions() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Cancel All Subscriptions</h1>
            <p className="mb_40 them_para text-center mx-auto">
              Note: You are cancelling all subscriptions for all of your
              swimmers.{" "}
            </p>
            <div className="btn_wraper">
              {/* <button type="button" className="them_btn mt_40 btn mb_30">
                Continue
              </button> */}
              <Link to="/subscriptionCancelledSuccessfully">
                <button type="button" className="them_btn_ouline mt_40 mb_30">
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
