import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import http from "../services/http";
import GetStorage from "../services/Storage";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";

export default function CheckoutForm() {
  const packagesID = JSON.parse(localStorage.getItem("packagesID"));

  const video = JSON.parse(localStorage.getItem("video"));

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setLoading(true);

          http
            .post(`api/parent/order?token=${token}`, {
              package_id: packagesID.id,
              recording_id: video.id,
              payment_intent: paymentIntent,
            })
            .then((res) => {
              setLoading(false);

              navigate("/thankYouForYourOrder");
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.errors) {
                  for (let key in error.response.data.errors) {
                    setError(error.response.data.errors[key]);
                  }
                } else if (error.response.data.message) {
                  setError(error.response.data.message);
                }
              }
            });
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://argo-parent-portal.herokuapp.com/stripePayment",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SpinnerCircular size="7%" />
          </div>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
