import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function PayLater() {
  return (
    <>
      <body>
        <Header />
        <section class="otp_verification custom_hight">
          <div class="login_inner pt-0">
            <h1 class="mb_30 main_title ">Pay Later</h1>
            <p class="mb_40 them_para text-center mx-auto">
              Note: Your swimmer will not be filmed until payment is completed.
              Check the box below to be reminded.{" "}
            </p>
            <form action="index_submit" method="get" class="custom_check_box">
              <div class="form-group">
                <label class="check_box">
                  {" "}
                  Remind Me
                  <input type="checkbox" checked="checked" />{" "}
                  <span class="checkmark"></span>{" "}
                </label>
              </div>
            </form>
            <div class="btn_wraper">
              <Link to="">
                <button type="button" class="them_btn_ouline mt_40 mb_30">
                  Submmit
                </button>
              </Link>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
