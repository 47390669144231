import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Left from "../assets/images/left.png";
import logo from "../assets/images/logo.png";
import Header from "./Header";
export default function OtpNumber() {
  return (
    <>
      <>
        <body>
          <Header />

          <section className="otp_verification custom_hight">
            <div className="login_inner  pt-0">
              <h1 className="mb_30 main_title ">OTP Verification</h1>
              <p className="mb_40 them_para text-center mx-auto">
                We have sent a 5 digit OTP to +189******46
                <br />
                Please enter the OTP to continue
              </p>
              <div className="otp_wraper">
                <input
                  type="text"
                  name=""
                  value=""
                  className="otp_input "
                  maxlength="1"
                  placeholder="2"
                />
                <input
                  type="text"
                  name=""
                  value=""
                  className="otp_input "
                  maxlength="1"
                  placeholder="3"
                />
                <input
                  type="text"
                  name=""
                  value=""
                  className="otp_input "
                  maxlength="1"
                  placeholder="4"
                />
                <input
                  type="text"
                  name=""
                  value=""
                  className="otp_input "
                  maxlength="1"
                  placeholder="2"
                />
                <input
                  type="text"
                  name=""
                  value=""
                  className="otp_input "
                  maxlength="1"
                  placeholder="6"
                />{" "}
              </div>
              <div className="btn_wraper">
                <Link to="/welcomeJessica">
                  <button type="button" className="them_btn mt_40 mb_30">
                    Continue
                  </button>
                </Link>
              </div>
              <p className="them_para mx-auto  mt_40">
                Didn't Receive Code?
                <a href="" className="btn-link  ">
                  {" "}
                  Resend Code
                </a>
              </p>
            </div>
          </section>
        </body>
      </>
    </>
  );
}
