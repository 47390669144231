import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import polygon from "../assets/images/Polygon 56.png";
import Header from "./Header";
import { useSelector } from "react-redux";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import GetStorage from "../services/Storage";
import http from "../services/http";
import dots from "../assets/images/dots.svg";
import { showToast } from "./ShowToast";
import { Table, Space, Spin } from 'antd'

export default function EvoOrderSummery() {
  const selectvideo = useSelector((state) => state.selectvideo.selectVideo);
  // console.log("selected vide------", selectvideo.id);
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const packagesID = JSON.parse(localStorage.getItem("packagesID"));
  // console.log("packeages------", packagesID.id);
  useEffect(() => {
    localStorage.removeItem("viewed");
    localStorage.removeItem("url");
  }, []);

  const getVideoToken = async (id, type, code) => {
    setLoading(true);
    http
      .post(`api/common/video_token`, {
        code: code,
        token: token,
      })
      .then((res) => {
        GetVideo(res.data.video_token, id, type);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  }
  const GetVideo = async (video_token, id, type) => {
    http
      .post(`api/common/get_video?token=${token}`, {
        video_token: video_token,
        type: type,
        id: id,
      })
      .then((res) => {
        setLoading(false);

        localStorage.setItem("url", JSON.stringify(res.data));
        navigate("/logsVideo");
        console.log("navigate ------->>>>");
      })
      .catch((error) => {

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const WatchVideoCode = async (type) => {
    setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        const code = res.data.code;
        getVideoToken(selectvideo.id, type, code);
        //navigate(`/otpLogs/${selectvideo.id}/${type}`);
        // setLocker(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const WatchVideopackage = async (type) => {
    // setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        const code = res.data.code;
        getVideoToken(packagesID.id, type, code);
        //console.log("response ------->>>>", res.data);
        //navigate(`/otpLogs/${packagesID.id}/${type}`);
        // setLocker(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification">
          {/*  */}
          <div className="login_inner custom_hight pt-0">
            <h1 className="mb_30 main_title ">eVO Order Summary</h1>
            <div className="table_vedio_upload  container mt_40 mb_3 0 over_flow  mb-0">
              <h2 className="mb_40 them_para text-left  ml-0 heading">
                Video Selected
              </h2>
              <table className="table table-borderless  table-responsive-lg">
                <thead>
                  <tr>
                    {/* <th scope="col" className="  pl_25">
                      Video
                    </th> */}
                    <th scope="col" className=" " style={{ paddingLeft: 20 }}>
                      First Name
                    </th>
                    <th scope="col" className=" ">
                      Last Name
                    </th>
                    <th scope="col" className=" ">
                      Stroke type
                    </th>
                    <th scope="col" className=" ">
                      Team
                    </th>
                    <th scope="col" className=" ">
                      Group
                    </th>
                    {/*  <th scope="col" className=" ">
                      Duration
                    </th> */}
                    <th scope="col" className=" ">
                      Date filmed
                    </th>
                    <th scope="col" className="  text-center">
                      Actions
                    </th>
                    <th scope="col" className="  pr_80 text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/*  <td className="pl_25">
                      <Link
                        to="/video"
                        state={{
                          id: selectvideo.id,
                          name: selectvideo.outputFilenameCam1,
                        }}
                      >
                        <video width={100} height={70}>
                          <source src={selectvideo.outputFilenameCam1} />
                        </video>
                        <img
                          src={polygon}
                          alt=""
                          style={{
                            position: "absolute",
                            marginTop: 25,
                            marginLeft: -60,
                            width: 20,
                            height: 20,
                          }}
                        />
                      </Link>
                    </td> */}
                    <td style={{ paddingLeft: 20 }}>{selectvideo.firstname}</td>
                    <td>{selectvideo.lastname}</td>
                    <td>{selectvideo.stroke}</td>
                    <td>{selectvideo.team}</td>
                    <td>{selectvideo.groupName}</td>
                    {/* <td>1m 23s</td> */}
                    <td>
                      {" "}
                      {moment(selectvideo.created_at).format("MMM Do YY")}
                    </td>

                    <td>
                      <div className="btn_wraper d-flex justify-content-center">
                        <button
                          type="button"
                          className="them_btn "
                          onClick={() => navigate("/UnSubscrbeTable")}
                        >
                          Select other video
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="btn_wraper d-flex justify-content-center">
                        <button
                          type="button"
                          className="them_btn "
                          onClick={() => WatchVideoCode("recording")}
                        >
                          Show video
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table_vedio_upload  container mt_40 mb_3 0 over_flow  mb-0">
              <p className="mb_40 them_para text-left  ml-0 heading ">
                Package Selected
              </p>
              {
                loading ? (<Space size="middle">
                  <Spin size="large" />
                </Space>) : <table className="table table-borderless  table-responsive-lg">
                  <thead>
                    <tr>
                      {/* <th scope="col" className="  pl_25">
                      Video
                    </th> */}
                      <th scope="col" className=" " style={{ paddingLeft: 20 }}>
                        Package name
                      </th>
                      <th scope="col" className=" ">
                        Delivery Time
                      </th>
                      <th scope="col" className=" ">
                        Price
                      </th>
                      <th scope="col" className="  pr_80 "></th>
                      <th scope="col" className="  prs "></th>
                      <th scope="col" className="   text-center">
                        Actions
                      </th>
                      <th scope="col" className="  pr_80 text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td className="pl_25">
                      <Link
                        to="/video"
                        state={{
                          name: packagesID.sample,
                        }}
                      >
                        <video width={100} height={70}>
                          <source src={packagesID.sample} />
                        </video>
                        <img
                          src={polygon}
                          alt=""
                          style={{
                            position: "absolute",
                            marginTop: 25,
                            marginLeft: -60,
                            width: 20,
                            height: 20,
                          }}
                        />
                      </Link>
                    </td> */}
                      <td style={{ paddingLeft: 20 }}>{packagesID.name}</td>
                      <td>Delivered Within {packagesID.duration} days</td>
                      <td>${packagesID.price}.00</td>
                      <td></td>
                      <td></td>

                      <td>
                        <div className="btn_wraper d-flex justify-content-center">
                          <button
                            type="button"
                            className="them_btn "
                            onClick={() => navigate("/selecctAnEvo")}
                          >
                            Select other package
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className="btn_wraper d-flex justify-content-center">
                          <button
                            type="button"
                            className="them_btn "
                            onClick={() => WatchVideopackage("package")}
                          >
                            Show video
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>}
            </div>
            <div className="btn_wraper">
              <Link to="/checkOut">
                <button type="button" className="them_btn_ouline mt_40 mb_30">
                  Proceed to checkout
                </button>
              </Link>
            </div>
          </div>
          {/* // } */}
        </section>
      </body>
    </>
  );
}
