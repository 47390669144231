import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

export default function ManageCurrentSwimmersUpdate() {
  return (
    <body>
      <Header />
      <section className="otp_verification custom_hight">
        <div className="login_inner pt-0">
          <h1 className="mb_30 main_title ">Manage Current Swimmers</h1>
          <p className="mb_40 them_para text-center mx-auto text_limit">
            You have “x” swimmers selected for subscription. Monthly
            subscription total will be $xx.{" "}
          </p>
          <div className="list_detail">
            <ul>
              <li>
                {" "}
                <b>Amanda Doe</b>
                <span>$24.00</span>{" "}
              </li>
              <li>
                {" "}
                <b>Joseph Doe</b>
                <span>$24.00</span>{" "}
              </li>
            </ul>
            <hr />
            <ul>
              <li>
                {" "}
                <b>Total</b>
                <span>$48.00</span>{" "}
              </li>
            </ul>
          </div>
          <div className="btn_wraper">
            <Link to="/subscriptionComplete">
              <button className="them_btn">Update Subscription</button>
            </Link>
          </div>
        </div>
      </section>
    </body>
  );
}
