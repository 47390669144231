import React, { useState, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import shere from "../assets/images/share-post-symbol.png";
import Modal from "react-bootstrap/Modal";
import http from "../services/http";
import GetStorage from "../services/Storage";
import { useDispatch } from "react-redux";
import { setInput, setShareToken, setVideo } from "../store/auth/inputvalue";
import arrowright from "../assets/images/play.png";
import icons8pause from "../assets/images/icons8-pause-30.png";
import mutepic from "../assets/images/mute-512.webp";
import unmute from "../assets/images/unmute.png";
import "../assets/css/video.css";
import UseVideoPlayer from "./UseVideoPlayer";

const ShareVideos = () => {
  const location = useLocation();
  const { name } = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setVideo(name));

  const [showModal, setShow] = useState(false);
  const [error, setError] = useState("");

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  const handleChanges = (event) => {
    dispatch(setInput(event.target.value));
  };

  const token = GetStorage.get("xAuthToken");

  const shareVideo = async () => {
    http
      .get(`api/parent/share-video-token?token=${token}`)
      .then((res) => {
        dispatch(setShareToken(res.data));
        navigate("/shareOtp");
        console.log("share in  ------->>>>", res.data);
      })
      .catch((error) => {
        console.log("error----->", error.response);
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const videoElement = useRef(null);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
  } = UseVideoPlayer(videoElement);
  return (
    <>
      <svg
        onClick={() => navigate(-1)}
        style={{
          color: "white",
          position: "absolute",
          width: 20,
          height: 15,
          marginLeft: 20,
          marginTop: 20,
          zIndex: 1,
        }}
        viewBox="4 4 33.583 27.008"
      >
        <path
          id="Path_83491"
          d="M 37.58322906494141 15.81591987609863 L 10.46499538421631 15.81591987609863 L 19.90085029602051 6.380063056945801 L 17.50390815734863 3.999999761581421 L 3.999999761581421 17.50390815734863 L 17.50390815734863 31.0078125 L 19.88397026062012 28.62774848937988 L 10.46499538421631 19.19189453125 L 37.58322906494141 19.19189453125 L 37.58322906494141 15.81591987609863 Z"
        ></path>
      </svg>
      <div>
        <img
          onClick={handleShow}
          src={shere}
          alt=""
          style={{
            position: "absolute",
            width: 25,
            height: 20,
            marginLeft: 1340,
            marginTop: 50,
            zIndex: 1,
          }}
        />
      </div>
      <div className="video-wrapper">
        <video
          style={{ objectFit: "contain", width: "100%", height: "100vh" }}
          src={name}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
        />

        <div className="controls">
          <div className="actions">
            <button onClick={togglePlay}>
              {!playerState.isPlaying ? (
                <img
                  src={arrowright}
                  style={{ width: 25, height: 25 }}
                  alt=""
                />
              ) : (
                <img
                  src={icons8pause}
                  style={{ width: 20, height: 20 }}
                  alt=""
                />
              )}
            </button>
          </div>
          <input
            type="range"
            min="0"
            max="100"
            value={playerState.progress}
            onChange={(e) => handleVideoProgress(e)}
          />
          <select
            className="velocity"
            value={playerState.speed}
            onChange={(e) => handleVideoSpeed(e)}
          >
            <option value="0.50">0.50x</option>
            <option value="1">1x</option>
            <option value="1.25">1.25x</option>
            <option value="2">2x</option>
          </select>
          <button className="mute-btn" onClick={toggleMute}>
            {!playerState.isMuted ? (
              <img
                src={unmute}
                style={{
                  width: 20,
                  height: 20,
                  // paddingLeft: 650,
                }}
                alt=""
              />
            ) : (
              <img src={mutepic} style={{ width: 20, height: 20 }} alt="" />
            )}
          </button>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Share video</Modal.Title>
          <button
            onClick={handleClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">&times;</span>{" "}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body social_text">
            <p>
              Lorem ipsum dolor sit amet, consectetur acing elit. nun c non
              risus condiment lobortittyr at ef sollicitu.
            </p>
            <div className="form-group icon mt-3 w-100 Call_Number">
              <input
                type="text"
                className="form-control"
                placeholder="Call Number"
                onChange={handleChanges}
              />
              <div className="img_icon">
                <button
                  type="button"
                  className="them_btn "
                  onClick={shareVideo}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareVideos;
