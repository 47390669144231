import './css/antdTable.css'
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dropdown from "../assets/images/dropdown.png";
import Header from "./Header";
import GetStorage from "../services/Storage";
import http from "../services/http";
import polygon from "../assets/images/Polygon 56.png";
import moment from "moment";
import { setSelectVideo } from "../store/auth/selectEvo";
import { useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dots from "../assets/images/dots.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { showToast } from "./ShowToast";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import { DeleteTwoTone, DownCircleOutlined, DownOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
export default function UnSubscrbeTable() {
  const token = GetStorage.get("xAuthToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [locker, setLocker] = useState([]);
  const [category, setCotegory] = useState([]);
  const [selectedcategory, setselectedcategory] = useState();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [showDate, setShowDate] = useState("");

  const swimmerdata = JSON.parse(localStorage.getItem("swimmerdata"));
  const gridStyle = {
    width: "100%",
    textAlign: "center"
  };

  function formatAMPM(date) {
    var hours = Date.parse(date).getHours();
    var minutes = Date.parse(date).getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'outputFilenameCam1',
      key: 'name',
      render: (_, record) => (
        <Link to={"#"} onClick={() =>
          WatchVideoCode(record.id, "recording")} >
          {" "}
          {record.outputFilenameCam1
            .split("/")
          [
            record.outputFilenameCam1.split("/").length - 1
          ].toUpperCase()}

        </Link>

      ),
    },
    {
      title: 'Stroke Type',
      dataIndex: 'stroke',
      sorter: (a, b) => a.stroke - b.stroke,
    },
    {
      title: 'Date Filmed',
      dataIndex: 'timestamp',
      render: (_, record) => (

        <div> {" "}
          {moment(record.timestamp).format("MMM Do YY")}
        </div>
      ),

    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      render: (_, item) => (
        <div >
          <DeleteTwoTone title='Delete recording' style={{ fontSize: '30px', }} onClick={() => {
            setRecordingDeleteId(item.id)
            showModal()
          }} />
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, item) => (
        <table>
          <td>
            <div >
              <button
                type="button"
                className="them_btn "
                onClick={() => OrderAnEvo(item)}
              >
                Order an eVO
              </button>
            </div>
          </td>
          {/* <td>
            <div >
              <button
                type="button"
                className="them_btn "
                onClick={() =>
                  WatchVideoCode(item.id, "recording")
                }
              >
                Show video
              </button>
            </div>
          </td> */}
        </table>
      ),
    },
  ];


  const categoryfunction = () => {
    http
      .get(`api/category?token=${token}`)
      .then((res) => {
        console.log("strock---> ", res.data);
        setCotegory(res.data);
      })
      .catch((error) => {
        console.log("error in dashbord---->", error.message);
      });
  };
  useEffect(() => {
    ParentLocker();
    localStorage.removeItem("viewed");
    localStorage.removeItem("url");
    categoryfunction();
    // return () => {
    //   categoryfunction();
    // };
  }, []);

  const ParentLocker = async () => {
    setLoading(true);

    http
      .get(`api/parent/swimmer/${swimmerdata.id}/locker?token=${token}`)
      .then((res) => {
        console.log("locker in  ------->>>>", res.data);
        setLocker(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const handleChanges = (value) => {
    setselectedcategory(value);
    // console.log("change------>", event.target.value);
  };

  const showVideo = (item) => {
    dispatch(setSelectVideo(item));
    localStorage.setItem("video", JSON.stringify(item));
    // navigate("/learnMoreAboutEVO");
  };

  const handleDate = (date) => {
    const accurateDate = moment(date.$d).format("YYYY-MM-DD");
    console.log(accurateDate);
    setShowDate(date);
    setStartDate(accurateDate);
  };

  const SearchStrockAndDate = (category) => {
    let path = `api/parent/swimmer/${swimmerdata.id}/locker?token=${token}`;
    if (category) {
      path += `&stroke=${category}`;
    }
    else if (startDate && selectedcategory) {
      path += `&stroke=${selectedcategory}&date=${startDate}`;
    } else if (startDate && !selectedcategory) {
      path += `&date=${startDate}`;
    } else if (selectedcategory && !startDate) {
      path += `&stroke=${selectedcategory}`;
    }
    setLoading(true);
    http
      .get(path)
      .then((res) => {
        console.log("locker in  ------->>>>", res.data);
        setLocker(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const Clear = () => {
    setStartDate("");
    setShowDate("");
    ParentLocker();
    setCotegory([]);
    categoryfunction();
    setselectedcategory(null);
  };

  const items = [
    {
      label: <button
        type="button"
        className="them_btn "
      >
        Delete
      </button>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <button
        type="button"
        className="them_btn "
      >
        Order an eVO
      </button>,
      key: '1',
    },

  ];
  const OrderAnEvo = (item) => {
    dispatch(setSelectVideo(item));
    showVideo(item);
    navigate("/selecctAnEvo");
  };
  const getVideoToken = async (id, type, code) => {
    setLoading(true);
    http
      .post(`api/common/video_token`, {
        code: code,
        token: token,
      })
      .then((res) => {
        GetVideo(res.data.video_token, id, type);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  }
  const GetVideo = async (video_token, id, type) => {
    http
      .post(`api/common/get_video?token=${token}`, {
        video_token: video_token,
        type: type,
        id: id,
      })
      .then((res) => {
        setLoading(false);

        localStorage.setItem("url", JSON.stringify(res.data));
        navigate("/logsVideo");
        console.log("navigate ------->>>>");
      })
      .catch((error) => {

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const WatchVideoCode = async (id, type) => {
    setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        setLoading(false);
        const code = res.data.code;
        getVideoToken(id, type, code);
        //navigate(`/otpLogs/${id}/${type}/${code}`);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const { Option } = Select

  const [open, setOpen] = useState(false);
  const [deleteId, setRecordingDeleteId] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Are you sure want to delete?');

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    http
      .delete(`api/parent/deleterecording/${deleteId}?token=${token}`)
      .then((res) => {
        setOpen(false);
        setConfirmLoading(false);
        ParentLocker();
        SearchStrockAndDate(selectedcategory);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });

    setTimeout(() => {

    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleMenuClick = (e, item) => {
    if (e.key == 0) {
      setRecordingDeleteId(item.id)
      showModal()
    } else if (e.key == 1) {
      OrderAnEvo(item);
    }

  }
  const getFileName = (fullName) => {
    console.log(fullName
      .split("/")
    [
      fullName.split("/").length - 1
    ].toUpperCase())
    return fullName
      .split("/")
    [
      fullName.split("/").length - 1
    ].toUpperCase()
  }
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification">
          <div className="login_inner custom_hight">
            <h1 className="mb_20 main_title ">
              {swimmerdata.firstname}
            </h1>
            <p className="mb_20 them_para text-center mx-auto them_para_2 ">
              We offer eVOs (Educational Voiceovers)..
              <Link to="/educationalVoiceOvers" className="btn-link  d-block">
                {" "}
                Click here to learn about the eVOs.
              </Link>
            </p>
            <h6 style={{ backgroundColor: "red" }}>{error}</h6>
            <div class="search_filter_outer mb" style={{ marginLeft: "3em" }}>
              <div class="serach_filter_button">
                <div class="form-group icon mx-0 Edit_Setting">

                  <Select style={{ width: 200 }} defaultValue="All Stroke Type" size={'large'} onChange={value => {
                    setselectedcategory(value)
                    SearchStrockAndDate(value);
                  }}
                    value={selectedcategory}>
                    <Option key={"all-0"} value={0}>
                      {"All Stroke Type"}
                    </Option>
                    {category?.map((item, i) => (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>

                  {/* <div className="img_icon">
                    <img src={dropdown} alt="" />{" "}
                  </div> */}
                </div>
                {/* <div class="form-group icon mx-0">
                  <DatePicker size={'large'}
                    format="YYYY-MM-DD"
                    onChange={(date) => handleDate(date)}
                    placeholderText="Search by date"
                  />


                </div> */}
                {/* <div class="btn_wraper_search ">
                   <button
                    type="button"
                    class="them_btn  "
                    onClick={SearchStrockAndDate}
                  >
                    Search
                  </button> 
                  <button
                    type="button"
                    class="them_btn_ouline  "
                    onClick={Clear}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    class="them_btn_Unsubscribed mt-0  mobile_show"
                  >
                    Subscribed
                  </button>
                </div> */}
              </div>

            </div>
            <Modal
              title="Delete Recording"
              centered
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <p>{modalText}</p>
            </Modal>
            {loading ? (<Space size="middle">
              <Spin size="large" />
            </Space>) : <div className="custom_containers mb_40 over_flow">
              <div className="table_vedio_upload    mt_20 mb_30 over_flow">
                {/* <div style={{ margin: `50px`, display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
                  <div className="e-card e-card-horizontal" style={{ width: `400px` }}>
                    <img src="./Code.png" alt="Sample" style={{ height: `180px` }} />
                    <div className="e-card-stacked">
                      <div className="e-card-header">
                        <div className="e-card-header-caption">
                          <div className="e-card-header-title">Philips Trimmer</div>
                        </div>
                      </div>
                      <div className="e-card-content">
                        Powered by the innovative DuraPower Technology which optimizes power consumption, Philips trimmers are designed to last longer
                        than 4 ordinary trimmers.
                      </div>
                    </div>
                  </div>
                </div> */}
                <Card>

                  {locker?.map((item, i) => (
                    <Card.Grid style={gridStyle} className="mobile_show">
                      <Row justify={"space-between"}>
                        {/* <Col >
                        <Link
                          to="/shareVideosVoice"
                          state={{ name: item.outputFilenameCam1 }}
                        >
                          <video src={item.outputFilenameCam1} className="img-fluid" />
                          <img src={polygon} alt="" className="play-btn" />
                        </Link>
                      </Col> */}
                        <Col>
                          <Row justify={"center"}>

                            <Text strong>
                              <Link to={"#"} onClick={() =>
                                WatchVideoCode(item.id, "recording")} >
                                {" "}
                                {item.outputFilenameCam1
                                  .split("/")
                                [
                                  item.outputFilenameCam1.split("/").length - 1
                                ].toUpperCase()}

                              </Link>
                            </Text>
                          </Row>
                          <Row justify={"space-between"}>
                            <Col><Text secondary strong>Stroke:</Text></Col>
                            <Col><Text secondary strong>Date Filmed:</Text></Col>
                          </Row>
                          <Row justify={"space-between"}>
                            <Col><Text secondary>{item.stroke}</Text></Col>
                            <Col><Text secondary>{moment(item.created_at).format("MMM Do YY")}</Text></Col>
                          </Row>
                          <Row justify={"center"}>
                            <Col>
                              <AntdDropdown placement="top" arrow menu={{ items, onClick: (e) => { handleMenuClick(e, item) }, }} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                  <Space>
                                    More
                                    <DownCircleOutlined />
                                  </Space>
                                </a>
                              </AntdDropdown>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    </Card.Grid>
                  ))}



                </Card>
                {/* <List 
                  dataSource={locker}
                  renderItem={(item) => (
                    <List.Item key={item.outputFilenameCam1}>
                      <List.Item.Meta
                        title={<Link to={"#"} onClick={() =>
                          WatchVideoCode(item.id, "recording")} >
                          {" "}
                          {item.outputFilenameCam1
                            .split("/")
                          [
                            item.outputFilenameCam1.split("/").length - 1
                          ].toUpperCase()}
                        </Link>}
                        description={item.email}
                      />
                      <div>Content</div>
                    </List.Item>
                  )}
                /> */}

                <Table className='custom-css-table desktop_show' columns={columns} dataSource={locker}></Table>
              </div>
            </div>
            }

          </div>
        </section>
      </body>


    </>
  );
}
