import React, { useState, createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { showToast } from "./ShowToast";
import OTPInput, { ResendOTP } from "otp-input-react";

const ShareOtp = (props) => {
  const navigate = useNavigate();
  //   const craditional = useSelector((state) => state.login.craditional);
  const sharetoken = useSelector((state) => state.input.shareToken);
  const phonenumber = useSelector((state) => state.input.inputvalue);
  const video = useSelector((state) => state.input.video);
  console.log("phonenumber--->", phonenumber);
  const [OTP, setOTP] = useState("");

  const sharetokens = sharetoken.share_token;

  const token = GetStorage.get("xAuthToken");
  const user = GetStorage.get("user");

  // const phonenumber = user.data.phone;

  //   const ResendCode = () => {
  //     http.post(`public/parent/password/reset-code`, craditional).then((res) => {
  //       console.log("response==>", res.data);
  //       showToast("success", "code has been sent");
  //     });
  //   };

  const handleSubmit = async () => {
    http
      .post(`api/parent/share-video?token=${token}`, {
        share_token: sharetokens,
        code: OTP,
        video_url: video,
        phone: phonenumber,
      })
      .then((res) => {
        console.log("response ------->>>>", res.data);
        navigate("/UnSubscrbeTable");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  };
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification custom_hight">
          <div className="login_inner  pt-0">
            <h1 className="mb_30 main_title ">OTP Verification </h1>
            <p className="mb_40 them_para text-center mx-auto">
              We have sent a 6 digit OTP to your phone number.
              <br />
              Please enter the OTP to continue
            </p>

            <div className="otp_wraper">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                inputStyles={{ marginLeft: 0, marginRight: 8, width: 48, height: 48 }}
                inputClassName="otp_input"
              />
            </div>
            <div className="btn_wraper">
              <button
                type="submit"
                className="them_btn mt_40 mb_30"
                onClick={handleSubmit}
              >
                Continue
              </button>
            </div>
            {/*  <div style={{ flexDirection: "row" }}>
              <p className="them_para mx-auto  mt_20">
                Didn't Receive Code?
                <p
                  className="btn-link  "
                  onClick={ResendCode}
                  style={{
                    position: "absolute",
                    marginTop: -36,
                    marginRight: 50,
                    paddingLeft: 380,
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  Resend Code
                </p>
              </p>
            </div> */}
          </div>
        </section>
        <ToastContainer />
      </body>
    </>
  );
};

export default ShareOtp;
