import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import GetStorage from "../services/Storage";
import http from "../services/http";
import polygon from "../assets/images/Polygon 56.png";
import { SpinnerCircular } from "spinners-react";
import upload from "../assets/images/upload.png";



const SwimmerCard = (props) => {
  const { swimmers, SelectedSwimmer } = props;
  return <>
    <div className="row">
      {swimmers.map((item, key) => (
        <div class="col-lg-3 col-md-4 col-sm-6 col-12" key={key} >
          <div class="box_work" onClick={() => SelectedSwimmer(item)}>
            <div class="img-wraper border_green">
              {" "}
              <img
                class="img-wraper wh"
                src={upload}
                alt=""

              />
            </div>
            <h6 className="sub_title  ">
              {item.firstname} {item.lastname}
            </h6>
          </div>
        </div>
      ))}
    </div>
  </>
}

const CategoryCard = (props) => {
  const { categories, SelectedCategory } = props;
  return <>
    <div>
      {categories.map((item, key) => (
        <div class="" key={key} >
          <div class="box_work" style={{ minHeight: "50px" }} onClick={() => SelectedCategory(item)}>

            <h6 className="sub_title  ">
              {item.stroke}
            </h6>
          </div>
        </div>
      ))}
    </div>
  </>
}

const RecordingCard = (props) => {
  const { recordings, category_id } = props;
  const categorywiseRecording = recordings.filter(item => item.category_id == category_id);
  // console.log(Object.keys(category).map((item, index) => {
  //   console.log(item, array);

  // }));
  return <>
    {
      categorywiseRecording.map((item, key) =>
      (

        <div
          className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
          key={key}
        >
          <div className="vedio_datile">
            <div
              className="vedio"
              style={{ position: "relative" }}
            >
              <Link
                to="/shareVideosVoiceSwimmer"
                state={{ name: item.outputFilenameCam1 }}
              >
                <video src={item.outputFilenameCam1} className="img-fluid" />
                <img src={polygon} alt="" className="play-btn" />
              </Link>
            </div>
            <div className="text-show-file" style={{ "fontSize": "17.3px" }} >
              <b> Swimmer:</b> {item.firstname} {item.lastname}
            </div>
            <div className="text-show-file" style={{ "fontSize": "17.3px" }}>
              <b> Stroke: </b>{item.stroke}
            </div>
            <div className="text-show-file" style={{ "fontSize": "17.3px" }}>
              <b>Team:</b> {item.team} {" "}   <b>Group:</b>{item.groupName}
            </div>
          </div>

        </div>
      ))}

  </>
}
export default function SwimmerLocker() {
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [swimmers, setSwimmers] = useState([]);
  const [selectedSwimmer, setSelectedSwimmer] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { parentid } = useParams();

  useEffect(() => {
    ParentLocker();
  }, []);

  const ParentLocker = async () => {
    setLoading(true);

    http
      .post(`public/parent/swimmerswithlockers`, { parentid: parentid })
      .then((res) => {
        const sw = [];
        const rec = [];
        const cat = [];

        Object.keys(res.data).map((item, index) => {
          sw.push(res.data[item].swimmer);
        })

        setSwimmers(sw);
        setGroupData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const backswimmerCard = () => {
    setSelectedSwimmer(null);
  }
  const backCategoryCard = () => {
    setSelectedCategory(null);
  }

  const SelectedSwimmer = (swimmer) => {
    setSelectedSwimmer(swimmer);
  }

  const SelectedCategory = (category) => {
    setSelectedCategory(category);
  }
  return (
    <>
      <Header />

      <section className="otp_verification">
        <div className="login_inner custom_hight pt-0">
          {/* <h1 className="mb_30 main_title ">
            eVO's: Educational VoiceOvers{" "}
          </h1>
          <p className="mb_40 them_para text-center mx-auto them_para_2 ">
            Educational VocieOvers are an essential part of the learning
            process for young swimmers.
          </p> */}
          <div className="custom_container mb_40">
            <div >
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    marginTop: 100,
                  }}
                >
                  <SpinnerCircular size="7%" />
                </div>
              ) : (
                !selectedSwimmer ? <SwimmerCard swimmers={swimmers} SelectedSwimmer={SelectedSwimmer}></SwimmerCard>
                  : !selectedCategory ? <>
                    <div className="btn_wraper">
                      <button
                        type="button"
                        className="them_btn mt_40 mb_30"
                        onClick={backswimmerCard}
                      >
                        Go Back
                      </button>
                    </div>
                    <div className="row">
                      <CategoryCard categories={groupData[selectedSwimmer.swimmer_id].categories} SelectedCategory={SelectedCategory}></CategoryCard>
                    </div>
                    <div className="btn_wraper">
                      <button
                        type="button"
                        className="them_btn mt_40 mb_30"
                        onClick={backswimmerCard}
                      >
                        Go Back
                      </button>
                    </div></> :
                    <>
                      <div className="btn_wraper">
                        <button
                          type="button"
                          className="them_btn mt_40 mb_30"
                          onClick={backCategoryCard}
                        >
                          Go Back
                        </button>
                      </div>
                      <div className="row">
                        <RecordingCard recordings={groupData[selectedSwimmer.swimmer_id].recordings} category_id={selectedCategory.category_id} ></RecordingCard>
                      </div>
                      <div className="btn_wraper">
                        <button
                          type="button"
                          className="them_btn mt_40 mb_30"
                          onClick={backCategoryCard}
                        >
                          Go Back
                        </button>
                      </div>
                    </>
              )}
            </div>

          </div>
        </div>
      </section>
    </>
  );
}
