import React from "react";
import Header from "./Header";

export default function SwimClubFAQ() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification  ">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Swim Club FAQ</h1>
            <div className="container Parent_FAQ">
              <p className="  them_para  w-100  text-left">
                Q: Does the team buy the camera or pay a fee to keep it at our
                pool?{" "}
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Argo does not sell the camera. The team must make a deposit
                of $2500 to secure against damage, loss or theft.{" "}
              </p>
              <p className="  them_para  w-100  text-left">
                Q: How do subscriptions work insofar as keeping the camera?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Argo requires that 51% of all the kids on the team subscribe
                at $15 per month, September 1st thru April 30th. When your team
                hits that number, your deposit is returned in full and your team
                keeps the camera indefinitely. Should your subscription fall
                below 51%, your team will be required to return the camera
                immediately or pay the difference. There is no penalty for
                missing the % number and having to return the camera. All
                scheduled subscription payments stop as soon as the camera is
                returned.{" "}
              </p>
              <p className="  them_para  w-100  text-left">
                Q: What happens during the long-course season?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Lots of kids stop swimming when short course season ends, so
                there is no penalty for taking a summer break from your
                subscription. Argo drops the 51% requirement May 1st -August
                31st, but leaves the camera in place at your pool for the
                summer. Our PayPal billing service allows you to “skip the
                summer” if you like, leaving your payment information in place
                and simply resuming your subscription payments automatically on
                September 1st.
              </p>
              <p className="  them_para  w-100  text-left">
                Q: Can you give examples of filming techniques and review?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Review of the video is an essential part of video-teaching.
                Sometimes the review happens “one-to-many”. Example: if 5 kids
                are filmed for 20 seconds each doing a drill, your coach may
                take a quick look at each swimmers’ video and be satisfied that
                they are doing the drill correctly. Swimmers may then view their
                “drill video” on DropBox later. In the same example, the coach
                might take the tablet and kneel down to quickly show the 5
                swimmers what each of them are doing; right or wrong. Drill
                continues. Your coach will use the video as part of his/her
                balanced video-teaching plan. It’s their call as to when, where
                and in what manner to conduct review of your swimmer’s video.{" "}
              </p>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
