import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { Formik, ErrorMessage } from "formik";
import { SpinnerCircular } from "spinners-react";
import { showToast } from "./ShowToast";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import dropdown from "../assets/images/dropdown.png";
import storage from "../services/Storage";
import { setToken } from "../store/auth/authState";

export default function Register() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const token = GetStorage.get("xAuthToken");

    const [firstname, setfirstName] = useState("");
    const [lastname, setLastname] = useState("");
    const [error, setError] = useState("");
    const [teams, setTeams] = useState([]);
    const [parent, setParent] = useState({});

    const initialValues = {
        firstname: "",
        lastname: "",
        gender: "MALE",
        email: "",
        phone: "",
        team_id: "",
    };

    const validationSchema = Yup.object({
        firstname: Yup.string().required("FirstName is required."),
        lastname: Yup.string().required("LastName is required."),
        email: Yup.string().required("Email is required."),
        phone: Yup.string().required("Cell number is required."),
        team_id: Yup.string().required("Team is required."),
    });


    useEffect(() => {
        loadTeam()
    }, []);

    const loadTeam = () => {
        http
            .get(`public/parent/allteam`)
            .then((res) => {
                console.log("response----->", res.data);
                setTeams(res.data);

                // setLoading(false);
            })
            .catch((error) => {
                // setLoading(false);
            });
    };

    const onChangeProp = (event, key) => {
        parent[key] = event.target.value
        setParent(parent);
    };

    const handleChanges = (event) => {
        // setTeamId(event.target.value);
    };
    const onSubmits = async (values, { resetForm }) => {
        http
            .post(`public/parent/registerparent`, values)
            .then((res) => {
                showToast("success", res.data.message);
                navigate("/");
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            setError(error.response.data.errors[key]);
                            showToast("error", error.response.data.errors[key]);
                        }
                    } else if (error.response.data.message) {
                        showToast("error", error.response.data.message);

                        setError(error.response.data.message);
                    }
                }
            });
    };

    return (
        <>
            <Header />
            <section className="otp_verification">
                <div className="custom_containers mb_40 over_flow">
                    <div id="content">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmits}>
                            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <>
                                    <div className="dashbord_content">
                                        <div className="dashobord_inner new_team_add">
                                            <h5 className="my-3">New Parent Registration</h5>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> First Name</label>
                                                            <input
                                                                type="text"
                                                                id="firstname"
                                                                name="firstname"
                                                                onChange={handleChange("firstname")}
                                                                onBlur={handleBlur("firstname")}
                                                                value={values.firstname}
                                                                className="custom_input form-control"
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                <ErrorMessage name="firstname" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Last Name</label>
                                                            <input
                                                                type="text"
                                                                id="lastname"
                                                                name="lastname"
                                                                onChange={handleChange("lastname")}
                                                                onBlur={handleBlur("lastname")}
                                                                value={values.lastname}
                                                                className="custom_input form-control"
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                <ErrorMessage name="lastname" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Gender</label>
                                                            <select
                                                                id="gender"
                                                                name="gender"
                                                                onChange={handleChange("gender")}
                                                                onBlur={handleBlur("gender")}
                                                                value={values.gender}
                                                                className="form-control custom_input "
                                                            >
                                                                <option
                                                                    value="MALE"
                                                                >
                                                                    Male
                                                                </option>
                                                                <option
                                                                    value="FEMALE"
                                                                >
                                                                    Female
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Email Address</label>
                                                            <input
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                onChange={handleChange("email")}
                                                                onBlur={handleBlur("email")}
                                                                value={values.email}
                                                                className="custom_input form-control"
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                <ErrorMessage name="email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Cell Number</label>
                                                            <ReactInputMask placeholder="+1 999-999-9999" className="custom_input form-control" id="phone"
                                                                name="phone" mask={"+1 999-999-9999"}
                                                                onBlur={handleBlur("phone")}
                                                                onChange={e => {
                                                                    let phone = e.currentTarget.value.replace(/\(|\)|\s|-/g, "")
                                                                    setFieldValue('phone', phone)
                                                                    if (phone.length >= 12 || phone.length >= 10) {
                                                                        values['phone'] = phone;
                                                                    }
                                                                }}
                                                                value={values.phone}
                                                            >
                                                            </ReactInputMask>
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                <ErrorMessage name="phone" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Select team</label>
                                                            <div class=" icon mx-0 Edit_Setting">
                                                                <select
                                                                    id="team_id"
                                                                    name="team_id"
                                                                    onChange={handleChange("team_id")}
                                                                    onBlur={handleBlur("team_id")}
                                                                    value={values.team_id}
                                                                    className="form-control custom_input"
                                                                >
                                                                    <option value="" selected>
                                                                        --- Select team ---
                                                                    </option>
                                                                    {teams?.map((item, i) => (
                                                                        <option key={i} value={item.id}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <div className="img_icon">
                                                                    <img src={dropdown} alt="" />{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                <ErrorMessage name="team_id" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="fomr-group" style={{ fontSize: 'xx-large' }}>
                                                            <label> By clicking Register, you confirm that you're the owner or primary user of this mobile phone number.</label>
                                                            <br />
                                                            <label> You agree to receive automated SMS to confirm your phone number. Message and data rates may apply.</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Select team</label>
                                                            <div class=" icon mx-0 Edit_Setting">
                                                                <select
                                                                    onChange={handleChanges}
                                                                    name="Team"
                                                                    className="custom_input form-control mb-0 "
                                                                >
                                                                    <option value="0" selected>
                                                                        --- Select team ---
                                                                    </option>


                                                                </select>
                                                                <div className="img_icon">
                                                                    <img src={dropdown} alt="" />{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}


                                                    <div className="col-12 d-flex justify-content-end">
                                                        <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                                                            <button
                                                                onClick={() => navigate("/")}
                                                                type="button"
                                                                className=" them_btn_ouline  "
                                                            >
                                                                Login
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="them_btn "
                                                                onClick={handleSubmit}
                                                            >
                                                                Register
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
            <ToastContainer />

        </>
    );
}
