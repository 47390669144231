import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectVideo: {},
};

const slice = createSlice({
  name: "selectvideos",
  initialState,
  reducers: {
    setSelectVideo: (state, action) => {
      state.selectVideo = action.payload;
    },
    updateVideo: (state, action) => {
      state.selectVideo = action.payload;
    },
    resetSeletVideo: (state, action) => {
      state = initialState;
    },
  },
});

export const { setSelectVideo, updateUser, resetSeletVideo } = slice.actions;
export default slice.reducer;
