import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import GetStorage from "../services/Storage";
import http from "../services/http";
import polygon from "../assets/images/Polygon 56.png";
import { SpinnerCircular } from "spinners-react";
import { Card, Col, Row, Space } from 'antd';
const { Meta } = Card;

export default function EducationalVoiceOvers() {
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ParentLocker();
  }, []);

  const ParentLocker = async () => {
    setLoading(true);

    http
      .get(`api/category?token=${token}`)
      .then((res) => {
        console.log("category in  ------->>>>", res.data);
        setCategory(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <body>
        <Header />

        <section className="otp_verification">
          <div className="login_inner custom_hight pt-0">
            <h1 className="mb_30 main_title ">
              eVO's: Educational VoiceOvers{" "}
            </h1>
            <p className="mb_40 them_para text-center mx-auto them_para_2 ">
              Educational VocieOvers are an essential part of the learning
              process for young swimmers.
            </p>
            <div className="custom_container mb_40">
              <div className="row">
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: 100,
                    }}
                  >
                    <SpinnerCircular size="7%" />
                  </div>
                ) : (
                  <>
                    {category.map((item, key) => (

                      <Col key={key} >
                        <Card
                          hoverable
                          style={{ width: 350, margin: 10 }}

                          cover={<div
                            className="vedio"
                            style={{ position: "relative" }}
                          >
                            <Link
                              to="/shareVideosVoice"
                              state={{ name: item.video }}
                            >
                              <video src={item.video} className="img-fluid" />
                              <img src={polygon} alt="" className="play-btn" />
                            </Link>
                          </div>}
                        >
                          <Meta title={item.name} />
                        </Card>
                      </Col>

                      // <div
                      //   className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                      //   key={key}
                      // >
                      //   <div className="vedio_datile">
                      //     <div
                      //       className="vedio"
                      //       style={{ position: "relative" }}
                      //     >
                      //       <Link
                      //         to="/shareVideosVoice"
                      //         state={{ name: item.video }}
                      //       >
                      //         <video src={item.video} className="img-fluid" />
                      //         <img src={polygon} alt="" className="play-btn" />
                      //       </Link>
                      //     </div>
                      //     <p className="text-show-file  mt-2">
                      //       {item.name}
                      //       {/*  {
                      //         item.video.split("/")[
                      //           item.video.split("/").length - 1
                      //         ]
                      //       } */}
                      //     </p>
                      //   </div>
                      // </div>
                    ))}
                  </>
                )}
              </div>
              <div className="btn_wraper">
                <button
                  type="button"
                  className="them_btn mt_40 mb_30"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
