import React from "react";
import Header from "./Header";

export default function PaymentHistory() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification  ">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Payment History</h1>
            <div className="accordion-wrap custom_container">
              <div id="accordion" className="faq-accordion">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      <span>September 2020</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="14"
                        viewBox="0 0 19 14"
                      >
                        <path
                          id="Polygon_96"
                          data-name="Polygon 96"
                          d="M9.5,0,19,14H0Z"
                          transform="translate(19 14) rotate(180)"
                          fill="#a1a1a1"
                        />{" "}
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="list_detail">
                        <ul>
                          <li className="mb-3">
                            Amanda Doe<span>$24.00</span>{" "}
                          </li>
                          <li className="mb-3">
                            Joseph Doe<span>$24.00</span>{" "}
                          </li>
                        </ul>
                        <hr />
                        <ul>
                          <li>
                            Total<span>$48.00</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      <span>August 2020</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="14"
                        viewBox="0 0 19 14"
                      >
                        <path
                          id="Polygon_96"
                          data-name="Polygon 96"
                          d="M9.5,0,19,14H0Z"
                          transform="translate(19 14) rotate(180)"
                          fill="#a1a1a1"
                        />{" "}
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="list_detail">
                        <ul>
                          <li className="mb-3">
                            Amanda Doe<span>$24.00</span>{" "}
                          </li>
                          <li className="mb-3">
                            Joseph Doe<span>$24.00</span>{" "}
                          </li>
                        </ul>
                        <hr />
                        <ul>
                          <li>
                            Total<span>$48.00</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      <span>July 2020</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="14"
                        viewBox="0 0 19 14"
                      >
                        <path
                          id="Polygon_96"
                          data-name="Polygon 96"
                          d="M9.5,0,19,14H0Z"
                          transform="translate(19 14) rotate(180)"
                          fill="#a1a1a1"
                        />{" "}
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="list_detail">
                        <ul>
                          <li className="mb-3">
                            Amanda Doe<span>$24.00</span>{" "}
                          </li>
                          <li className="mb-3">
                            Joseph Doe<span>$24.00</span>{" "}
                          </li>
                        </ul>
                        <hr />
                        <ul>
                          <li>
                            Total<span>$48.00</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      <span>June 2020</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="14"
                        viewBox="0 0 19 14"
                      >
                        <path
                          id="Polygon_96"
                          data-name="Polygon 96"
                          d="M9.5,0,19,14H0Z"
                          transform="translate(19 14) rotate(180)"
                          fill="#a1a1a1"
                        />{" "}
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="list_detail">
                        <ul>
                          <li className="mb-3">
                            Amanda Doe<span>$24.00</span>{" "}
                          </li>
                          <li className="mb-3">
                            Joseph Doe<span>$24.00</span>{" "}
                          </li>
                        </ul>
                        <hr />
                        <ul>
                          <li>
                            Total<span>$48.00</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
