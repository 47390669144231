import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { SpinnerCircular } from "spinners-react";
import { showToast } from "./ShowToast";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactInputMask from "react-input-mask";

export default function AddParent() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const token = GetStorage.get("xAuthToken");

    const initialValues = {
        firstname: "",
        lastname: "",
        gender: "MALE",
        email: "",
        phone: "",
    };


    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required("Required"),
        lastname: Yup.string().required("Required"),
        email: Yup.string().email().required(),
        // .when('phone', {
        //     is: (phone) => !phone || phone.length === 0,
        //     then: Yup.string().email().required(),
        //     otherwise: Yup.string()
        // }),
        // phone: Yup.string().when('email', {
        //     is: (email) => !email || email.length === 0,
        //     then: Yup.string().required(),
        //     otherwise: Yup.string()
        // })
    }, [['email']]);

    const onSubmits = async (values, { resetForm }) => {

        http
            .post(`api/parent/addparent?token=${token}`, values)
            .then((res) => {
                showToast("success", res.data.message);
                navigate("/manageparents")
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            setError(error.response.data.errors[key]);
                            showToast("error", error.response.data.errors[key]);
                        }
                    } else if (error.response.data.message) {
                        showToast("error", error.response.data.message);

                        setError(error.response.data.message);
                    }
                }
            });
    };

    return (
        <>


            <Header />
            <section className="otp_verification">
                <div className="custom_containers mb_40 over_flow">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmits}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <>
                                <div className="dashbord_content">
                                    <div className="dashobord_inner new_team_add">
                                        <h5 className="my-3">Additional Viewer Info</h5>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> First Name</label>
                                                        <input
                                                            type="text"
                                                            id="firstname"
                                                            name="firstname"
                                                            onChange={handleChange("firstname")}
                                                            onBlur={handleBlur("firstname")}
                                                            value={values.firstname}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="firstname" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Last Name</label>
                                                        <input
                                                            type="text"
                                                            id="lastname"
                                                            name="lastname"
                                                            onChange={handleChange("lastname")}
                                                            onBlur={handleBlur("lastname")}
                                                            value={values.lastname}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="lastname" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Gender</label>
                                                        <select
                                                            id="gender"
                                                            name="gender"
                                                            onChange={handleChange("gender")}
                                                            onBlur={handleBlur("gender")}
                                                            value={values.gender}
                                                            className="form-control custom_input w-100"
                                                        >
                                                            <option
                                                                value="MALE"
                                                            >
                                                                Male
                                                            </option>
                                                            <option
                                                                value="FEMALE"
                                                            >
                                                                Female
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Email Address</label>
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            name="email"
                                                            onChange={handleChange("email")}
                                                            onBlur={handleBlur("email")}
                                                            value={values.email}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Cell Number</label>
                                                        <ReactInputMask placeholder="+1 999-999-9999" className="custom_input form-control" id="phone"
                                                            name="phone" mask={"+1 999-999-9999"}
                                                            onBlur={handleBlur("phone")}
                                                            onChange={e => {
                                                                let phone = e.currentTarget.value.replace(/\(|\)|\s|-/g, "")
                                                                setFieldValue('phone', phone)
                                                                if (phone.length >= 12 || phone.length >= 10) {
                                                                    values['phone'] = phone;
                                                                }
                                                            }}
                                                            value={values.phone}
                                                        >
                                                        </ReactInputMask>
                                                        {/* <input
                                                            type="text"
                                                            id="phone"
                                                            name="phone"
                                                            onChange={e => {
                                                                let phone = e.currentTarget.value
                                                                const onlyDigitFound = phone.match(/^[+0-9]*$/);
                                                                if (onlyDigitFound) {
                                                                    setFieldValue('phone', phone)
                                                                } else {
                                                                    return
                                                                }
                                                                const found1 = phone.match(/^\+[1-9]{1}[0-9]{10,14}$/);
                                                                const found2 = phone.match(/^\+1{1}[0-9]{10}$/);
                                                                const found3 = phone.match(/^[0-9]{10}$/);
                                                                if (!found1 && !found2 && !found3 || phone.length < 10) {
                                                                    return;
                                                                } else {
                                                                    if (found3) {
                                                                        phone = '+1' + phone;
                                                                    }
                                                                    setFieldValue('phone', phone)
                                                                    if (phone.length >= 12 || phone.length >= 10) {
                                                                        values['phone'] = phone;
                                                                    }

                                                                }

                                                            }}
                                                            onBlur={handleBlur("phone")}
                                                            value={values.phone}
                                                            className="custom_input form-control"
                                                        /> */}
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="phone" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 d-flex justify-content-end">
                                                    <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                                                        <Link to="/manageparents">
                                                            <button
                                                                type="button"
                                                                className=" them_btn_ouline  "
                                                            >
                                                                Cancel
                                                            </button>
                                                        </Link>

                                                        <button
                                                            type="button"
                                                            to="/manageparents"
                                                            onClick={handleSubmit}
                                                            className=" them_btn  "
                                                        >
                                                            {loading ? (
                                                                <SpinnerCircular
                                                                    style={{ height: "40", marginTop: "7" }}
                                                                />
                                                            ) : (
                                                                "  Save"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </section>
            <ToastContainer />
        </>
    );
}
