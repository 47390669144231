import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function SubscribeYourSwimmer() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Subscribe Your Swimmer(s)</h1>
            <p className="mb_40 them_para text-center mx-auto">
              You have “x” swimmers selected for subscription. Monthly
              subscription total will be $xx.{" "}
            </p>
            <form
              action="index_submit"
              method="get"
              className="custom_check_box"
            >
              <div className="form-group">
                <label className="check_box">
                  {" "}
                  Amanda Doe
                  <input type="checkbox" checked="checked" />{" "}
                  <span className="checkmark"></span>{" "}
                </label>
              </div>
              <div className="form-group">
                <label className="check_box">
                  Joseph Doe
                  <input type="checkbox" /> <span className="checkmark"></span>{" "}
                </label>
              </div>
            </form>
            <div className="btn_wraper">
              <Link to="/payNow">
                <button type="button" className="them_btn_ouline mt_40 mb_30">
                  Pay now
                </button>
              </Link>
              <Link to="/payLater">
                <button type="button" className="them_btn_ouline mt_40 mb_30">
                  Pay Later
                </button>
              </Link>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
