import React, { useState, createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { showToast } from "./ShowToast";
import { setToken } from "../store/auth/authState";
import { setUser } from "../store/auth/user";
import storage from "../services/Storage";
import OTPInput, { ResendOTP } from "otp-input-react";
import { SpinnerCircular } from "spinners-react";
import Left from "../assets/images/left.png";
import logo from "../assets/images/logo.png";

export default function OtpVerification(props) {
  const navigate = useNavigate();
  const craditional = useSelector((state) => state.login.craditional);
  const [OTP, setOTP] = useState("");
  const [uploadSpin, setUploadSpin] = useState(false);
  const [resendCode, setresendCode] = useState("");

  const dispatch = useDispatch();

  const ResendCode = () => {
    http.post(`public/parent/auth`, craditional).then((res) => {
      setresendCode(res.data.token);

      showToast("success", "code has been sent");
    });
  };

  useEffect(() => {
    const token = GetStorage.get("xAuthToken");
    // const strockScreen = localStorage.getItem("screen");
    // const routeArr = JSON.parse(strockScreen);

    // console.log("test ------->>>>", routeArr[0], routeArr[1]);

    setresendCode(token);
  }, []);
  const handleOtpChange = async (e) => {
    setOTP(e);
    if (e.length == 6) {
      handleSubmit(e)
    }
  }
  const handleSubmit = async (otp) => {
    setUploadSpin(true);
    let user = localStorage.getItem("user");

    user = JSON.parse(user);
    const isVerifyPhoneCall = storage.get("isVerifyPhoneCall");
    let verifyRequestBody = {
      code: otp,
      token: resendCode,
    }
    if (!user.data) {
      verifyRequestBody.isVerifyPhoneCall = 0
    }
    else if (isVerifyPhoneCall) {
      verifyRequestBody.isVerifyPhoneCall = isVerifyPhoneCall
    }
    http
      .post(`public/verify`, verifyRequestBody)
      .then((res) => {
        // console.log("otp response ------->>>>", res.data);
        setUploadSpin(false);

        const { data } = res;
        const { token, token1 } = data;
        let user = data;

        dispatch(setToken(token));
        dispatch(setUser(data));

        //storage.store("isVerifyPhoneCall", 1);
        storage.remove("isVerifyPhoneCall");


        storage.store("xAuthToken", token);
        storage.store("xAuth", token1);
        storage.store("user", user);
        const strockScreen = localStorage.getItem("screen");
        if (isVerifyPhoneCall == 1) {
          navigate("/profile");
        }
        else if (strockScreen) {
          const routeArr = JSON.parse(strockScreen);
          if (routeArr[0] === "videosByStrock" && routeArr[1] !== "null") {
            navigate(`/videosByStrock/${routeArr[1]}`);
          } else {
            navigate("/welcomeJessica");
          }
        } else {
          navigate("/welcomeJessica");
        }
      })
      .catch((error) => {
        setUploadSpin(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <body>
        <header>
          <div className="custom_container ">
            <div className="login_logo  mb_40">
              <button
                style={{
                  backgroundColor: "transparent",
                  marginRight: 20,
                  borderWidth: 0,
                }}
                onClick={() => navigate(-1)}
              >
                <img src={Left} alt="" />{" "}
              </button>
              <img src={logo} alt="" />{" "}
            </div>
          </div>
        </header>

        <section className="otp_verification custom_hight">
          <div className="login_inner  pt-0">
            <h1 className="mb_30 main_title ">OTP Verification </h1>
            <p className="mb_40 them_para text-center mx-auto">
              We have sent a 6 digit OTP to your phone number
              <br />
              Please enter the OTP to continue
            </p>

            <div className="otp_wraper">
              <OTPInput
                value={OTP}
                onChange={handleOtpChange}
                autoFocus
                OTPLength={6}
                otpType="number"
                inputStyles={{ marginLeft: 0, marginRight: 8, width: 48, height: 48 }}
                inputClassName="otp_input"
              />
            </div>
            {/* <div className="btn_wraper">
              <button
                type="submit"
                className="them_btn mt_40 mb_30"
                onClick={handleSubmit}
              >
                {uploadSpin ? (
                  <SpinnerCircular className="button-spin" />
                ) : (
                  "Continue"
                )}
              </button>
            </div> */}
            <div className="otp-resend">
              <p className="opt-receive-code">Didn't Receive Code?</p>
              <p className="otp-btn-link" onClick={ResendCode}>
                {" "}
                Resend Code
              </p>
            </div>
          </div>
        </section>
        <ToastContainer />
      </body>
    </>
  );
}
