import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "../assets/images/dropdown.png";
import video from "../assets/images/vedio.png";
import Header from "./Header";

export default function UnSubscribe() {
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification">
          <div className="login_inner custom_hight pt-0">
            <h1 className="mb_30 main_title ">Swimmer: Joseph Doe</h1>
            <p className="mb_40 them_para text-center mx-auto them_para_2 ">
              We also offer evo(s) educational voiceovers on your children's
              videos. Click here to learn more about evo(s).
            </p>
            <div className="custom_container mb_40">
              <div className="search_filter_outer">
                <div className="serach_filter_button">
                  <div className="form-group icon mx-0 ">
                    <input
                      type="text"
                      name=""
                      value=""
                      className="custom_input form-control mb-0 mr-2"
                      placeholder="Search by stroke"
                    />
                    <div className="img_icon">
                      <a href="">
                        <img src={Dropdown} alt="" />{" "}
                      </a>
                    </div>
                  </div>
                  <div className="form-group icon mx-0">
                    <input
                      type="text"
                      name=""
                      value=""
                      className="custom_input form-control mb-0"
                      placeholder="Search by date"
                    />
                    <div className="img_icon">
                      <a href="">
                        <img src={Dropdown} alt="" />{" "}
                      </a>
                    </div>
                  </div>
                  <div className="btn_wraper_search ">
                    <button type="button" className="them_btn  ">
                      Search
                    </button>
                    <button type="button" className="them_btn_ouline  ">
                      Clear
                    </button>
                    <button
                      type="button"
                      className="them_btn_Unsubscribed mt-0  mobile_show"
                    >
                      Subscribed
                    </button>
                  </div>
                </div>
                <div className="btn_Subscribed">
                  <button
                    type="button"
                    className="them_btn_Unsubscribed  disktop-show"
                  >
                    Subscribed
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="vedio_datile">
                    <a href="" title="">
                      <div className="vedio">
                        {" "}
                        <img src={video} className="img-fluid" alt="" />{" "}
                      </div>
                      <p className="text-show-file  mt-2">
                        FIRSTNAME.LASTNAME.TEAM.STROKE.DATE.TIME
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
