import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "../assets/images/dropdown.png";
import video from "../assets/images/vedio.png";
import Header from "./Header";
import GetStorage from "../services/Storage";
import http from "../services/http";
import polygon from "../assets/images/Polygon 56.png";

export default function Subscribe() {
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [locker, setLocker] = useState([]);
  const [category, setCotegory] = useState([]);

  const location = useLocation();
  const { id, name } = location.state;

  useEffect(() => {
    ParentLocker();
    categoryfunction();
  }, []);

  const ParentLocker = async () => {
    http
      .get(`api/parent/swimmer/${id}/locker?token=${token}`)
      .then((res) => {
        console.log("locker in  ------->>>>", res.data);
        setLocker(res.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const categoryfunction = () => {
    http
      .get(`api/category?token=${token}`)
      .then((res) => {
        console.log("strock---> ", res.data);
        setCotegory(res.data);
      })
      .catch((error) => {
        console.log("error in dashbord---->", error.message);
      });
  };

  const handleChanges = (event) => {
    console.log("on change value---->", event.target.value);
    let path = `api/parent/swimmer/${id}/locker?stroke=${event.target.value}&token=${token}`;
    if (event.target.value > 0) {
      path += `&category_id=${event.target.value}`;
    }
    http
      .get(path)
      .then((res) => {
        console.log("and key ===>>", res.data);
        setLocker(res.data);
      })
      .catch((error) => {
        console.log("error ", error.response.data.errors);
      });
  };

  return (
    <>
      <body>
        <Header />

        <section className="otp_verification">
          <div className="login_inner custom_hight pt-0">
            <h1 className="mb_30 main_title ">Swimmer: {name}</h1>
            <p className="mb_40 them_para text-center mx-auto them_para_2 ">
              We also offer evo(s) educational voiceovers on your children's
              videos.{" "}
              <Link to="/educationalVoiceOvers" className="btn-link  d-block">
                {" "}
                Don't have access to your cell phone?
              </Link>
            </p>
            <div className="custom_container mb_40">
              <div className="search_filter_outer">
                <div className="serach_filter_button">
                  <div className="form-group icon   Edit_Setting ">
                    <select
                      onChange={handleChanges}
                      name="Team"
                      className="custom_input form-control mb-0"
                    >
                      <option value="0" selected>
                        Search by stroke
                      </option>
                      {category?.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>

                    <div className="img_icon">
                      <a href="">
                        <img src={Dropdown} alt="" />{" "}
                      </a>
                    </div>
                  </div>
                  <div className="form-group icon mx-0">
                    <input
                      type="text"
                      name=""
                      value=""
                      className="custom_input form-control mb-0"
                      placeholder="Search by date"
                    />
                    <div className="img_icon">
                      <a href="">
                        <img src={Dropdown} alt="" />{" "}
                      </a>
                    </div>
                  </div>
                  <div className="btn_wraper_search ">
                    <button type="button" className="them_btn  ">
                      Search
                    </button>
                    <button type="button" className="them_btn_ouline  ">
                      Clear
                    </button>
                    {/*    <button
                      type="button"
                      className="them_btn_Subscribed mt-0  mobile_show"
                    >
                      Subscribed
                    </button> */}
                  </div>
                </div>
                {/*      <div className="btn_Subscribed">
                  <button
                    type="button"
                    className="them_btn_Subscribed  disktop-show"
                  >
                    Subscribed
                  </button>
                </div> */}
              </div>
              <div className="row">
                {locker.map((item, key) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                    key={key}
                  >
                    <div className="vedio_datile">
                      <div className="vedio">
                        <Link
                          to="/video"
                          state={{ name: item.outputFilenameCam1 }}
                        >
                          <video
                            src={item.outputFilenameCam1}
                            className="img-fluid"
                          />
                          <img
                            src={polygon}
                            alt=""
                            style={{
                              position: "absolute",
                              marginTop: -100,
                              width: 30,
                              height: 30,
                              marginLeft: 130,
                            }}
                          />
                        </Link>
                      </div>
                      <p className="text-show-file  mt-2">
                        {
                          item.outputFilenameCam1.split("/")[
                            item.outputFilenameCam1.split("/").length - 1
                          ]
                        }
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
