import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { setToken } from "../store/auth/authState";
import { setUser } from "../store/auth/user";
import upload from "../assets/images/upload.png";
import { useDispatch } from "react-redux";
import { setSwimmers } from "../store/auth/swimmer";
import { SpinnerCircular } from "spinners-react";
import { Card, Col, Radio, Row, Space, Typography, Modal, Button, Input, Select } from 'antd';
import OTPInput, { ResendOTP } from "otp-input-react";
import storage from "../services/Storage";
import dropdown from "../assets/images/dropdown.png";


import "./css/card.css"
import SwimmerGrid from "./SwimmerGrid";

const { Text } = Typography;
const style = StyleSheet.Cr
const { Meta } = Card;
export default function WelcomeJessica() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [swimmer, setSwimmer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openVerifySwimmer, setOpenVerifySwimmer] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [teams, setTeams] = useState([]);
  const [selectedTeamId, setCurrentTeam] = useState(0);
  let user = localStorage.getItem("user");
  const { Option } = Select

  user = JSON.parse(user);
  useEffect(() => {
    getCurrentParent();
    loadTeam()
    //ParentSwimmer();
  }, []);

  const getCurrentParent = () => {
    http
      .get(`api/parent/getCurrentParent/${user.data.id}?token=${token}`)
      .then((res) => {
        if (!res.data.isphoneverified) {
          setOpen(true);
          setPhoneNumber(res.data.phone);
        }
        else if (!res.data.isswimmerverified && user && !user.data.mainparentphone) {
          setOpenVerifySwimmer(true);
        }
      })
      .catch((error) => {

      });
  }



  const loadTeam = () => {
    let userid = user.data.mainparentphone ? user.data.mainparentphone : user.data.id;
    http
      .get(`public/parent/teamsbyswimmers/${userid}`)
      .then((res) => {
        console.log("response----->", res.data);
        setTeams(res.data);
        setCurrentTeam(res.data[0].id)
        ParentSwimmer(res.data[0].id)
        // setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const ParentSwimmer = async (team_Id) => {
    let userid = user.data.mainparentphone ? user.data.mainparentphone : user.data.id;
    setLoading(true);
    http
      .get(`api/parent/swimmers/${team_Id}/${userid}?token=${token}`)
      .then((res) => {
        setSwimmer(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const verifyParentPhone = () => {
    http.post(`api/parent/verifyparentphone?token=${token}`, { phone: phoneNumber }).then((res) => {
      storage.remove("xAuth");

      const { data } = res;
      const { token } = data;
      let user = data;
      dispatch(setToken(token));
      //dispatch(setUser(data));
      storage.store("xAuthToken", token);
      //storage.store("user", user);
      storage.store("isVerifyPhoneCall", 1);
      navigate("/otpVerificaton");
    });
  }
  const SelectSwimmer = (item) => {
    dispatch(setSwimmers(item));
    localStorage.setItem("swimmerdata", JSON.stringify(item));
    navigate("/UnSubscrbeTable");
  };
  const [selectedChild, setChild] = useState(null);
  const onRadioChange = (e) => {
    setChild(e.target.value);
    SelectSwimmer(swimmer[e.target.value])
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    http.post(`api/parent/verifyparentphone?token=${token}`, { phone: phoneNumber }).then((res) => {
      storage.remove("xAuth");

      const { data } = res;
      const { token } = data;
      let user = data;
      dispatch(setToken(token));
      //dispatch(setUser(data));
      storage.store("xAuthToken", token);
      //storage.store("user", user);
      storage.store("isVerifyPhoneCall", 1);
      setOpen(false);
      setConfirmLoading(false);
      setError(null);
      navigate("/otpVerificaton");
    }).catch(err => {
      setConfirmLoading(false);
      if (err && err.response && err.response.data) {
        setError(err.response.data.message);
      }
    });

    // setTimeout(() => {
    //   setOpen(false);
    //   setConfirmLoading(false);
    // }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
    getCurrentParent();
  };

  const handleTeamChange = (teamId) => {
    setCurrentTeam(teamId);
    ParentSwimmer(teamId)
  }

  const handleOkSwimmerVerify = () => {
    setConfirmLoading(true);
    http.post(`api/parent/verifyswimmer?token=${token}`, {}).then((res) => {
      setOpenVerifySwimmer(false);
      setConfirmLoading(false);
      setError(null);
    }).catch(err => {
      setConfirmLoading(false);
      if (err && err.response && err.response.data) {
        setError(err.response.data.message);
      }
    });
  }
  return (
    <>
      <body>
        <Header />
        <Modal
          title="Verify your phone number!"
          open={open}
          onOk={handleOk}
          okText="Verify"
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <div>
            <Space direction="vertical" size="middle">
              <Space.Compact style={{ width: '100%' }}>
                {/* <Input style={{ width: '20%' }} defaultValue="+1" readOnly /> */}
                <Input style={{ width: '100%' }} value={phoneNumber} onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }} />
                {/* <Button type="primary" onClick={verifyParentPhone}>Verify</Button> */}
              </Space.Compact>
              <Space.Compact style={{ width: '100%' }}>
                {error && <div style={{ color: 'red' }}>{error}</div>}
              </Space.Compact>
              <Space.Compact style={{ width: '100%' }}></Space.Compact>
              <Space.Compact style={{ width: '100%' }}></Space.Compact>
            </Space>
          </div>
        </Modal>

        <Modal
          title="Verify your swimmer."
          open={openVerifySwimmer}
          width={"100%"}
          bodyStyle={{ height: "100%" }}
          onOk={handleOkSwimmerVerify}
          okText="Verify"
          confirmLoading={confirmLoading}
          cancelText="Cancel"
          onCancel={() => { setOpenVerifySwimmer(false); getCurrentParent(); }}
        >
          <section className="otp_verification">
            <div >

              {/* {user && !user.data.mainparentphone && <div className="col-12 d-flex justify-content-end">
                <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                  <div class="map_btn">
                    <Link to="/addswimmer" class="them_btn">
                      + Add Swimmer
                    </Link>
                  </div>
                </div>
              </div>
              } */}
              <div className="table_vedio_upload   mt_20 mb_30 over_flow">
                <Row justify={"end"}>
                  <Col className="gutter-row" >
                    <Button type="default" onClick={() => { setOpenVerifySwimmer(false); }} style={{ "marginBottom": 15 }}>Cancel</Button>
                    <Button style={{ "marginLeft": 5, "marginRight": 5, "marginBottom": 15 }} type="primary" onClick={handleOkSwimmerVerify}>Verify</Button>
                  </Col>
                </Row>
                <SwimmerGrid team_id={selectedTeamId}> </SwimmerGrid>
              </div>
            </div>

          </section>

        </Modal>

        <section class="otp_verification custom_hight">
          <div class="login_inner pt-0">
            <h1 class="mb_30 main_title ">Welcome {user.data.firstname} {user.data.lastname}!</h1>{" "}
            <p style={{ color: "red" }}>{error}</p>
            <p class="mb_40 them_para text-center mx-auto ">
              Click on a child to proceed.
            </p>
            <div className="row">
              <div className="col-md-12">
                <Row justify="center" >
                  <div className="col-md-4 col-sm-6">
                    <div className="fomr-group ">
                      <div class=" icon mx-0 Edit_Setting">
                        <Select style={{ width: 300 }}
                          size={'large'} value={selectedTeamId}
                          onChange={handleTeamChange}
                        >
                          {teams?.map((item, i) => (
                            <Option key={i} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>

                        {/* TODO : Remove This Old Drop Down */}
                        {/* <select
                          id="team_id"
                          name="team_id"
                          onChange={handleTeamChange}
                          //onBlur={handleBlur("team_id")}
                          //value={values.team_id}
                          className="form-control custom_input"
                        >
                          <option value="" selected>
                            --- Select team ---
                          </option>
                          {teams?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <div className="img_icon">
                          <img src={dropdown} alt="" />{" "}
                        </div> */}
                      </div>

                    </div>
                  </div>

                </Row>
              </div>
            </div>
            {swimmer && swimmer.length > 0 && <div>
              {user && !user.data.mainparentphone && <div class="map_btn mt-2">
                <Link to="/manageswimmers" class="them_btn" state={{ team_id: selectedTeamId }}>
                  Edit Swimmers
                </Link>
              </div>
              }

              <Row justify="center" >
                <Radio.Group onChange={onRadioChange} value={selectedChild}>
                  {loading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        // position: "absolute",
                        marginTop: 100,
                      }}
                    >
                      <SpinnerCircular size="10%" />
                    </div>
                  ) : (
                    <>
                      {swimmer.map((item, key) => (
                        <Col key={key} >
                          <Card onClick={() => {
                            SelectSwimmer(swimmer[key])
                          }}
                            hoverable
                            style={{ width: 250, margin: 10 }}
                          //onClick={() => SelectSwimmer(item)}
                          // cover={<img
                          //   style={{ padding: 10 }}
                          //   class=""
                          //   src={upload}
                          //   alt=""
                          //   onClick={}
                          // />}
                          >
                            <Radio value={key}> <Text strong>{item.firstname}</Text></Radio>
                          </Card>
                        </Col>
                        /* <div class="box_work">
                          <div class="img-wraper border_green">
                            {" "}
                            <img
                              class="img-wraper wh"
                              src={upload}
                              alt=""
                              onClick={() => SelectSwimmer(item)}
                            />
                          </div>
                          <h6 className="sub_title  ">
                            {item.firstname} {item.lastname}
                          </h6>
                        </div> */
                      ))}
                    </>
                  )}
                </Radio.Group>
              </Row>
            </div>}


          </div>
        </section>
      </body >
    </>
  );
}
