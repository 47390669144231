import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";

export default function ThankYouForYourOrder() {
  const navigate = useNavigate();
  const packagesID = JSON.parse(localStorage.getItem("packagesID"));

  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Thank You For Your Order</h1>
            <p className="mb_40 them_para text-center mx-auto text_limit">
              Your evo order will be delivered within {packagesID.duration}{" "}
              weeks
            </p>
            <div className="wraper_btn">
              <div className=" tn_wraper">
                <button
                  className="them_btn "
                  onClick={() => navigate("/unSubscrbeTable")}
                >
                  Back to Swimmer Locker
                </button>
                <Link to="/evoOrder">
                  <button type="button" className="them_btn_ouline ml-3   ">
                    View Orders Cart
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
