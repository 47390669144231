import React from "react";
import Header from "./Header";

export default function SubscriptionUpdatedSuccessfully() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">
              Subscription Updated Successfully
            </h1>
            <p className="mb_40 them_para text-center mx-auto  ">
              You now have “x” swimmers selected for subscription. Monthly
              subscription total will be $xx from next month.{" "}
            </p>
            <div className="wraper_btn">
              <div className=" tn_wraper">
                <button className="them_btn ">Done</button>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
