import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

export default function ManageCurrentSwimmersSubUn() {
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification custom_hight">
          <div className="login_inner">
            <h1 className="mb_30 main_title ">Manage Current Swimmers</h1>
            <p className="mb_40 them_para text-center mx-auto">
              You have “x” swimmers selected for subscription. Monthly
              subscription total will be $xx.{" "}
            </p>
            <form
              action="index_submit"
              method="get"
              className="custom_check_box custom_check_box_2"
            >
              <div className="form-group">
                <label className="check_box">
                  {" "}
                  Amanda Doe
                  <input type="checkbox" checked="checked" />{" "}
                  <span className="checkmark"></span>{" "}
                </label>{" "}
                <span className="sub_unsub_text">Subscribed</span>{" "}
              </div>
              <div className="form-group">
                <label className="check_box">
                  Joseph Doe
                  <input type="checkbox" /> <span className="checkmark"></span>{" "}
                </label>{" "}
                <span className="sub_unsub_text">Unsubscribed</span>{" "}
              </div>
            </form>
            <div className="btn_wraper">
              <Link to="/manageCurrentSwimmersUpdate">
                <button className="them_btn">Continue</button>
              </Link>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
