import React from "react";
import { Link, useNavigate } from "react-router-dom";
import dropdown from "../assets/images/dropdown.png";
import Header from "./Header";

export default function SelectGroup() {
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification   custom_hight ">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Select a group</h1>
            <p className="mb_40 them_para text-center mx-auto  ">
              Please select your children's group in order to proceed.
            </p>
            <form action="index_submit" className="Edit_Setting">
              <div className="form-group icon mt-3">
                <select name="" className="form-control custom_input">
                  <option value="">Group</option>
                  <option value="">Group1</option>
                </select>
                <div className="img_icon">
                  {" "}
                  <img src={dropdown} alt="" />{" "}
                </div>
              </div>
              <div className="form-group icon mt-3">
                <select name="" className="form-control custom_input">
                  <option value="">Swimmer Name</option>
                </select>
                <div className="img_icon">
                  {" "}
                  <img src={dropdown} alt="" />{" "}
                </div>
              </div>
            </form>
            <div className="btn_wraper">
              <Link to="/welcomeJesscaTwo">
                <button type="button" className="them_btn mt_40 mb_30">
                  Select
                </button>
              </Link>
            </div>
            <p className="them_para mx-auto  mt_40">
              Don't Have An Account?
              <a href="" className="btn-link  ">
                {" "}
                Register Now
              </a>
            </p>
          </div>
        </section>
      </body>
    </>
  );
}
