import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function SubscriptionComplete() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Subscription Complete</h1>
            <p className="mb_40 them_para text-center mx-auto text_limit">
              Whenever new video is uploaded to your swimmer’s locker, you will
              receive a text message alerting you to the addition.
            </p>

            <div className="btn_wraper">
              <Link to="/welcomeJesscaTwo">
                <button type="button" className="them_btn mt_40 mb_30">
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
