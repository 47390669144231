import React from "react";
import { Navigate } from "react-router-dom";
import GetStorage from "../services/Storage";
import { useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const user = GetStorage.get("user");

  const locArr = location.pathname.split("/");
  const exactRoute = locArr[1];

  if (!user) {
    if (exactRoute === "videosByStrock");
    {
      const id = locArr[2];
      localStorage.setItem("screen", JSON.stringify([exactRoute, id]));
    }
    return <Navigate to="/" replace />;
  }
  return children;
};
export default ProtectedRoute;
