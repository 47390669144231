import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { SpinnerCircular } from "spinners-react";

import { useSelector } from "react-redux";

const LearnMoreAboutEVO = () => {
  const token = GetStorage.get("xAuthToken");

  const selectvideo = useSelector((state) => state.selectvideo.selectVideo);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [ads, setAds] = useState("");
  const [controls, setControls] = useState(false);

  useEffect(() => {
    advideo();
  }, []);

  const PlayNext = () => {
    setAds(selectvideo.outputFilenameCam1);
    setControls(true);
  };

  const advideo = async () => {
    setLoading(true);
    http
      .get(`api/common/ad?token=${token}`)
      .then((res) => {
        // console.log("add ------->>>>", res.data.video);
        setAds(res.data.video);

        setLoading(false);
      })
      .catch((error) => {
        console.log("error ------->>>>", error.response);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <svg
        onClick={() => navigate(-1)}
        style={{
          color: "white",
          position: "absolute",
          width: 20,
          height: 15,
          marginLeft: 20,
          marginTop: 20,
          zIndex: 1,
        }}
        viewBox="4 4 33.583 27.008"
      >
        <path
          id="Path_83491"
          d="M 37.58322906494141 15.81591987609863 L 10.46499538421631 15.81591987609863 L 19.90085029602051 6.380063056945801 L 17.50390815734863 3.999999761581421 L 3.999999761581421 17.50390815734863 L 17.50390815734863 31.0078125 L 19.88397026062012 28.62774848937988 L 10.46499538421631 19.19189453125 L 37.58322906494141 19.19189453125 L 37.58322906494141 15.81591987609863 Z"
        ></path>
      </svg>
      <div
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              marginTop: -150,
              backgroundColor: "#ffffffc2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundBlendMode: "overlay",
              zIndex: 100,
            }}
          >
            <SpinnerCircular size="5%" />
          </div>
        ) : (
          <video
            width={"100%"}
            height={"100%"}
            src={ads}
            autoPlay
            playing={true}
            onEnded={PlayNext}
            controls={controls}
          />
        )}
      </div>
      <div
        style={{
          width: 200,
          height: 25,
          backgroundColor: "white",
          position: "absolute",
          marginLeft: 1200,
          marginTop: -90,
          zIndex: 1,
        }}
      >
        <Link to="/educationalVoiceOvers">
          <p style={{ marginLeft: 12 }}>Learn more about eVO's</p>
        </Link>
      </div>
    </>
  );
};

export default LearnMoreAboutEVO;
