import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setEmailForget } from "../store/auth/authState";
import { SpinnerCircular } from "spinners-react";
import GetStorage from "../services/Storage";
import http from "../services/http";

export default function ForgetPassword() {
  const token = GetStorage.get("xAuthToken");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const initialValues = {
    phone: "",
  };
  const validationSchema = Yup.object({
    phone: Yup.string().required("required"),
  });
  const ForgetPAssword = async (values) => {
    setLoading(true);
    dispatch(setEmailForget(values.phone));
    http
      .post(`public/parent/password/reset-request?token=${token}`, {
        phone: values.phone,
      })
      .then((res) => {
        // console.log("response in forget password", res.data);
        setLoading(false);
        setMessage(res.data.message);
        if (res.data.message === true) {
          navigate("/verifyYourEmail");
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log("error ", error.response.data.errors);
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={ForgetPAssword}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <>
            <section className="login_wraper">
              <div className="login_inner">
                <div className="login_logo mb_40">
                  {" "}
                  <img src={Logo} alt="" />{" "}
                </div>
                <h1 className="mb_30 main_title">Forgot Password</h1>
                <p className="mb_40 them_para">
                  Please enter your admin phone number so that we can send you a
                  6 digit otp to update password for this account.
                </p>
                <form
                  onSubmit={handleSubmit}
                  action="index_submit"
                  method="get"
                >
                  <div className="form-group icon">
                    <input
                      // type="email"
                      // name="email"
                      onChange={handleChange("phone")}
                      onBlur={handleBlur("phone")}
                      value={values.phone}
                      className="custom_input form-control"
                      placeholder="Phone"
                    />{" "}
                    <div
                      style={{
                        color: "red",
                        marginRight: 220,
                      }}
                    >
                      <ErrorMessage name="phone" />
                    </div>
                  </div>

                  <div style={{ padding: 2, marginLeft: 40 }}>
                    <h6 style={{ color: "red", alignSelf: "center" }}>
                      {error}
                    </h6>
                  </div>
                  <div className="btn_wraper">
                    <button
                      type="submit"
                      className="them_btn mt_40 mb_30 ml"

                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <SpinnerCircular className="button-spin" />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </form>
                <Link to="/">
                  <p
                    className="otp-btn-link"

                  >
                    Go Back
                  </p>
                </Link>
              </div>
            </section>
          </>
        )}
      </Formik>
    </>
  );
}
