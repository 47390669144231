import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link, useLocation } from "react-router-dom";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import GetStorage from "../services/Storage";
import http from "../services/http";
import SwimmerGrid from "./SwimmerGrid";
import dropdown from "../assets/images/dropdown.png";

export default function ManageSwimmers() {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [parents, setParents] = useState([]);
    const token = GetStorage.get("xAuthToken");
    const location = useLocation();
    const [teams, setTeams] = useState([]);
    const [selectedTeamId, setCurrentTeam] = useState(location.state?.team_id);
    const { Option } = Select;
    let user = localStorage.getItem("user");

    user = JSON.parse(user);
    useEffect(() => {
        const user = GetStorage.get("user");
        if (user && user.data?.mainparentphone) {
            window.location.href = "/"
        }
        loadTeam();
    }, []);

    const loadTeam = () => {
        let userid = user.data.mainparentphone ? user.data.mainparentphone : user.data.id;
        http
            .get(`public/parent/teamsbyswimmers/${userid}`)
            .then((res) => {
                console.log("response----->", res.data);
                setTeams(res.data);
                setCurrentTeam(res.data[0].id);


                // setLoading(false);
            })
            .catch((error) => {
                // setLoading(false);
            });
    };

    const handleTeamChange = (teamId) => {
        setCurrentTeam(teamId);
    }

    const SwimmerList = React.useCallback(() => {

        return <SwimmerGrid team_id={selectedTeamId}></SwimmerGrid>
    }, [selectedTeamId])

    return (
        <>
            <body>
                <Header />
                <section className="otp_verification">
                    <div className="custom_containers mb_40 over_flow">
                        <div className="col-12 d-flex justify-content-end">
                            <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                                <div className="row">
                                    <div className="fomr-group ">
                                        <div class=" icon mx-0 Edit_Setting">

                                            <Select style={{ width: 200 }}
                                                id="team_id"
                                                name="team_id"
                                                size={'large'}
                                                value={selectedTeamId}
                                                onChange={handleTeamChange}
                                            >
                                                {teams?.map((item, i) => (
                                                    <Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>

                                            {/* TODO : Remove after check DropDown */}
                                            {/* <select
                                                id="team_id"
                                                name="team_id"
                                                onChange={handleTeamChange}
                                                //onBlur={handleBlur("team_id")}
                                                value={selectedTeamId}
                                                className="form-control custom_input"
                                            >
                                                <option value="" selected>
                                                    --- Select team ---
                                                </option>
                                                {teams?.map((item, i) => (
                                                    <option key={i} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="img_icon">
                                                <img src={dropdown} alt="" />{" "}
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                                {/* <div class="map_btn">
                                    <Link to="/addswimmer" class="them_btn">
                                        + Add New Swimmer
                                    </Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="table_vedio_upload    mt_20 mb_30 over_flow">
                            <SwimmerList ></SwimmerList>
                        </div>
                    </div>
                </section>
            </body>
        </>
    );
}
