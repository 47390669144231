import React from "react";
import { Link } from "react-router-dom";
import AmandaDoe from "../assets/images/Amanda-Doe.png";
import JosephDoe from "../assets/images/Joseph-Doe.png";
import Header from "./Header";

export default function WelcomeJesscaTwo() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner  pt-0">
            <h1 className="mb_30 main_title ">Welcome Jessica!</h1>
            <p className="mb_40 them_para text-center mx-auto ">
              Please confirm if these are your kids. A red border indicates that
              a swimmer is not subscribed. Click Here To Subscribe.
            </p>
            <div className="custom_with_avilable custom_with_avilable2">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="box_work">
                    <div className="img-wraper border_green">
                      {" "}
                      <img src={AmandaDoe} alt="" />{" "}
                    </div>
                    <h6 className="sub_title  ">Amanda Doe</h6>{" "}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="box_work">
                    <div className="img-wraper border_red">
                      {" "}
                      <img src={JosephDoe} alt="" />{" "}
                    </div>
                    <h6 className="sub_title  ">Joseph Doe</h6>{" "}
                  </div>
                </div>
              </div>
              <div className="btn_wraper">
                <Link to="/subscriptionComplete">
                  <button type="button" className="them_btn mt_40 mb_30">
                    Invite Additional Member(s)
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
