import React, { useState, useEffect } from "react";
import { SpinnerCircular } from "spinners-react";
import upload from "../assets/images/play.png";
// import Header from "./Header";
import http from "../services/http";
import GetStorage from "../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Left from "../assets/images/left.png";
import logo from "../assets/images/logo.png";
import Shop from "../assets/images/shop.png";
import Menu from "../assets/images/menu.png";
import storageService from "../services/Storage";

const VideosByStrock = () => {
  const [loading, setLoading] = useState(true);
  const token = GetStorage.get("xAuthToken");
  const [strock, setStrock] = useState([]);
  const [error, setError] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  const signOutUser = () => {
    window.location.reload();

    try {
      storageService.remove("user");
      storageService.remove("xAuthToken");
      storageService.remove("xAuth");

      navigate("/");
    } catch (e) {
      console.log("logout error", e);
    }
  };

  useEffect(() => {
    localStorage.removeItem("screen");
    Strock();
  }, []);

  const Strock = async () => {
    setLoading(true);

    http
      .get(`api/parent/recordings/bycategory/${id}?token=${token}`)
      .then((res) => {
        console.log("single strock------->>>>", res.data);
        setStrock(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };
  const WatchVideoCodes = async (id, type) => {
    setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        console.log("video request-----", res.data);
        setLoading(false);
        navigate(`/otpLogs/${id}/${type}`);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  return (
    <body>
      <header>
        <div className="custom_container ">
          <div className="login_logo  mb_40">
            <button
              style={{
                backgroundColor: "transparent",
                marginRight: 20,
                borderWidth: 0,
                marginLeft: 0,
              }}
              // onClick={() => navigate(() => "/strockes")}
              onClick={() => navigate("/strockes")}
            >
              <img src={Left} alt="" />{" "}
            </button>
            <img src={logo} alt="" />{" "}
          </div>
          <div className="user_profile">
            <div className="collapse navbar-collapse show">
              <ul className="navbar-nav d-flex     flex-row align-items-center">
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <img
                      src={Shop}
                      className="mr-3"
                      onClick={() => navigate("/evoOrder")}
                    />
                  </a>
                </li>

                <Dropdown className="nav-item dropdown">
                  <Dropdown.Toggle>
                    <img src={Menu} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    aria-labelledby="navbarDropdownMenuLink "
                    className="dropdown-menu"
                  >
                    <Dropdown.Item
                      href="/swimmerParentFAQ"
                      class="dropdown-item"
                    >
                      Swimmer - Parent FAQ
                    </Dropdown.Item>

                    <Dropdown.Item
                      ClassName="dropdown-item"
                      href="/swimClubFAQ"
                    >
                      Swim Club - FAQ
                    </Dropdown.Item>

                    {/*  <Dropdown.Item
                  ClassName="dropdown-item"
                  href="/manageSubscription"
                >
                  Manage Subscription
                </Dropdown.Item>
                */}
                    <Dropdown.Item
                      ClassName="dropdown-item"
                      onClick={() => signOutUser()}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <section class="otp_verification custom_hight">
        <div class="login_inner pt-0">
          <h1 class="mb_30 main_title ">Strokes</h1>
          <p class="mb_40 them_para text-center mx-auto ">
            Click on a strok to play.
          </p>
          <div
            class="custom_with_avilable custom_with_avilable2 custom_with_1"
            style={{ maxWidth: 1000 }}
          >
            <div class="row">
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    // position: "absolute",
                    marginTop: 100,
                  }}
                >
                  <SpinnerCircular size="10%" />
                </div>
              ) : (
                <>
                  {strock.map((item, key) => (
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                      <div class="box_workstrock">
                        <div class="img-wraper ">
                          {" "}
                          <img
                            class="img-wraper wh"
                            src={upload}
                            alt=""
                            onClick={() =>
                              WatchVideoCodes(item.id, "recording")
                            }
                          />
                        </div>
                        <h6 className="sub_titleStrock  ">
                          {" "}
                          {
                            item.outputFilenameCam1.split("/")[
                              item.outputFilenameCam1.split("/").length - 1
                            ]
                          }
                        </h6>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </body>
  );
};

export default VideosByStrock;
