import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import GetStorage from "../services/Storage";
import helper from "../services/helper";
import http from "../services/http";

export default function ManageParents() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [parents, setParents] = useState([]);
  const token = GetStorage.get("xAuthToken");

  useEffect(() => {
    loadParents();
  }, []);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, record) => (

        <div> {" "}
          {helper.formatPhoneNumber(record.phone)}
        </div>
      ),
    },
  ];

  const loadParents = () => {
    setLoading(true);
    http
      .get(`api/parent/getallparents?token=${token}`)
      .then((res) => {
        setParents(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  }
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification">
          <div className="custom_containers mb_40 over_flow">
            <div className="col-12 d-flex justify-content-end">
              <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                <div class="map_btn">
                  <Link to="/addparent" class="them_btn">
                    Add New Viewer
                  </Link>
                </div>
              </div>
            </div>

            <div className="table_vedio_upload    mt_20 mb_30 over_flow">
              <Table className='custom-css-table ' columns={columns} dataSource={parents} ></Table>
            </div>
          </div>

        </section>
      </body>
    </>
  );
}
