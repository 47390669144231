import './css/antdTable.css'
import React, { useEffect, useState } from "react";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import { DeleteTwoTone, DownCircleOutlined, DownOutlined, EditOutlined, EditTwoTone } from '@ant-design/icons';
import { Link, useNavigate } from "react-router-dom";
import http from "../services/http";
import GetStorage from "../services/Storage";
import moment from "moment";

export default function SwimmerGrid(props) {
    const token = GetStorage.get("xAuthToken");
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [swimmers, setSwimmers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    let user = localStorage.getItem("user");
    user = JSON.parse(user);


    useEffect(async () => {
        LoadSwimmer();
    }, []);

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Date Of Birth',
            dataIndex: 'birthday',
            render: (_, record) => (

                <div> {" "}
                    {Date.parse(record.birthday) > 0 ? moment(record.birthday).format("MMM Do YYYY") : ''}
                </div>
            ),

        },
        {
            title: 'Team',
            dataIndex: 'team_name',
            key: 'team_name',
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            render: (_, item) => (
                <div >
                    <EditTwoTone title='Edit Swimmer' style={{ fontSize: '30px', marginRight: '10px' }} onClick={() => {
                        navigate("/addswimmer", { state: { swimmer_id: item.id } });
                    }} />
                    {/* <DeleteTwoTone title='Delete Swimmer' style={{ fontSize: '30px', }} onClick={() => {
                        // setRecordingDeleteId(item.id)
                        // showModal()
                    }} /> */}
                </div>
            ),
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     render: (_, item) => (
        //         <table>
        //             <td>
        //                 <div >
        //                     <button
        //                         type="button"
        //                         className="them_btn "
        //                         onClick={() => OrderAnEvo(item)}
        //                     >
        //                         Order an eVO
        //                     </button>
        //                 </div>
        //             </td>
        //         </table>
        //     ),
        // },
    ];

    const LoadSwimmer = () => {
        setLoading(true);
        let userid = user.data.mainparentphone ? user.data.mainparentphone : user.data.id;
        http
            .get(`api/parent/swimmers/${props.team_id}/${userid}?token=${token}`)
            .then((res) => {
                setSwimmers(res.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);

                if (error.response) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            setError(error.response.data.errors[key]);
                        }
                    } else if (error.response.data.message) {
                        setError(error.response.data.message);
                    }
                }
            });
    };

    return (
        <>
            <Table className='custom-css-table swimmergrid-table' style={{ height: "500px" }} pagination={false} columns={columns} dataSource={swimmers}></Table>
        </>
    );
}