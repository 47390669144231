import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

export default function EvoPackagesFiveMMint() {
  const navigate = useNavigate();
  const packagesID = JSON.parse(localStorage.getItem("packagesID"));
  const video = JSON.parse(localStorage.getItem("video"));

  const YesContinue = () => {
    navigate("/evoOrderSummery");
  };

  return (
    <>
      <body>
        <Header />

        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">
              evo Package: {packagesID.name}
            </h1>
            <p className="mb_40 them_para text-center mx-auto them_para_2  ">
              {packagesID.description
                ? packagesID.description
                : "There is no description for this package."}
            </p>
            <div className="vedio mx_450">
              {" "}
              <video
                src={video.outputFilenameCam1}
                style={{ width: 400 }}
                controls
                download
              />
              <p className="text-show-file  mt-2 d-flex justify-content-between">
                Duration: {packagesID.duration} min{" "}
                <span>Price: ${packagesID.price}.00</span>
              </p>
            </div>
            <div className="btn_wraper">
              <button
                type="button"
                className="them_btn mt_40 mb_30"
                onClick={() => navigate(-1)}
              >
                No, Select other
              </button>

              <button
                type="button"
                className="them_btn_ouline mt_40 mb_30"
                onClick={() => YesContinue()}
              >
                Yes, Continue
              </button>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
