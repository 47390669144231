import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logins from "../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../store/api/auth";
import { setCredational } from "../store/ui/loginUi";
import { SpinnerCircular } from "spinners-react";
import visable from "../assets/images/visibility.png";
import unvisable from "../assets/images/unvisibility.png";
import { LoginOutlined } from "@ant-design/icons";

export default function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uploadSpin, setUploadSpin] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const initialValues = {
    phone: "",
    //password: "",
  };
  const validationSchema = Yup.object({
    phone: Yup.string().required("required"),
    // password: Yup.string()
    //   .required("required")
    //   .min(5, "Password is too short - should be 5 chars minimum."),
  });
  const onSubmit = async (values, isEmailVerification) => {
    let requestBody = {}
    if (!isEmailVerification) {
      requestBody.phone = values.phone
    } else {
      requestBody.email = values.phone
    }
    setUploadSpin(true);
    dispatch(setCredational(values));
    dispatch(
      login({
        body: requestBody,
        // {
        //   phone: values.phone,
        //   //password: values.password,
        // },
        onSuccess: (res) => {
          setUploadSpin(false);
          navigate("/otpVerificaton");
        },

        onError: (error) => {
          setUploadSpin(false);

          if (error.response) {
            if (error.response.data.errors) {
              for (let key in error.response.data.errors) {
                setError(error.response.data.errors[key]);
              }
            } else if (error.response.data.message) {
              setError(error.response.data.message);
            }
          }
        },
      })
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <section className="login_wraper">
            <div className="login_inner">
              <div className="login_logo mb_40">
                {" "}
                <img src={logins} alt="" />{" "}
              </div>
              <h1 className="mb_30 main_title">Parent Login</h1>
              <p className="mb_40 them_para">
                Please enter valid phone to procced with the login process
              </p>
              <form>
                <div className="form-group icon">
                  <input
                    type="text"
                    name="phone"
                    onChange={e => {
                      let phone = e.currentTarget.value
                      let isEmailVerification = true;
                      const onlyDigitFound = phone.match(/^[+0-9]*$/);
                      setFieldValue('phone', phone)
                      if (onlyDigitFound) {
                        isEmailVerification = false;
                      } else {
                        const isEmail = phone.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
                        if (!isEmail) {
                          return
                        }
                      }
                      if (!isEmailVerification) {
                        const found1 = phone.match(/^\+[1-9]{1}[0-9]{10,14}$/);
                        const found2 = phone.match(/^\+1{1}[0-9]{10}$/);
                        const found3 = phone.match(/^[0-9]{10}$/);
                        if (!found1 && !found2 && !found3 || phone.length < 10) {
                          return;
                        } else {
                          if (found3) {
                            phone = '+1' + phone;
                          }
                          setFieldValue('phone', phone)
                          if (phone.length >= 12 || phone.length >= 10) {
                            values['phone'] = phone;
                            onSubmit(values, isEmailVerification)
                          }

                        }
                      }
                    }}
                    onBlur={handleBlur("phone")}
                    value={values.phone}
                    className="custom_input form-control"
                    placeholder="Enter Phone or Email"
                  />
                  <div
                    style={{
                      color: "red",
                      marginRight: 220,
                    }}
                  >
                    <ErrorMessage name="phone" />
                  </div>
                </div>
                {/* <div class="form-group icon">
                  <input
                    name="password"
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                    type={passwordType}
                    className="custom_input form-control"
                    placeholder="password"
                  />
                  <div class="img_icon">
                    {passwordType === "password" ? (
                      <a>
                        <img src={visable} alt="" onClick={togglePassword} />
                      </a>
                    ) : (
                      <a>
                        <img src={unvisable} alt="" onClick={togglePassword} />{" "}
                      </a>
                    )}
                  </div>
                </div> */}
                {/* <div
                  style={{
                    color: "red",
                  }}
                >
                  <ErrorMessage name="password" />
                </div> */}
                <h5 style={{ color: "red" }}>{error}</h5>
                <div className="btn_wraper">
                  {uploadSpin ? <SpinnerCircular className="button-spin" /> :

                    <LoginOutlined style={{ fontSize: '50px' }} onClick={handleSubmit} >
                    </LoginOutlined>
                  }

                  {/* <button
                    type="submit"
                    className="them_btn mt_40 mb_30"
                    onClick={handleSubmit}
                  >
                    {uploadSpin ? (
                      <SpinnerCircular className="button-spin" />
                    ) : (
                      "Login"
                    )}
                  </button> */}
                </div>{" "}
              </form>
              <p className="them_para mx-auto  mt_40">
                If you are new to the team and you are trying to setup your swimmers, click
                <a href="/register" className="btn-link  ">
                  {" "}
                  Register Now
                </a>
              </p>
              {/* <Link to="/forgetPassword">
                <p className="otp-btn-link">Forgot Password?</p>
              </Link> */}
            </div>
          </section>
        )}
      </Formik>
    </>
  );
}
