import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authReducer from "../store/auth/authState";
import authUser from "../store/auth/user";
import cves from "../store/auth/cv";
import Videos from "../store/auth/video";
import inputvalu from "../store/auth/inputvalue";
import loginUi from "../store/ui/loginUi";
import selectvideo from "../store/auth/selectEvo";
import oneSwimmer from "../store/auth/swimmer";

const combineReducer = combineReducers({
  auth: authReducer,
  user: authUser,
  cve: cves,
  video: Videos,
  input: inputvalu,
  login: loginUi,
  selectvideo: selectvideo,
  swimmer: oneSwimmer,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
