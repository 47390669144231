import React from "react";
import { useNavigate } from "react-router-dom";
import Left from "../assets/images/left.png";
import logo from "../assets/images/logo.png";
import Shop from "../assets/images/shop.png";
import Menu from "../assets/images/menu.png";

import Dropdown from "react-bootstrap/Dropdown";
import storageService from "../services/Storage";

export default function Header() {
  const navigate = useNavigate();

  let user = localStorage.getItem("user");

  user = JSON.parse(user);
  const signOutUser = () => {

    try {
      storageService.remove("isVerifyPhoneCall");
      storageService.remove("user");
      storageService.remove("xAuthToken");
      storageService.remove("xAuth");

      navigate("/");
      window.location.reload();

    } catch (e) {
      console.log("logout error", e);
    }
  };

  return (
    <>
      <header>
        <div className="custom_container ">
          <div className="login_logo  mb_40">
            <button
              style={{
                backgroundColor: "transparent",
                marginRight: 20,
                borderWidth: 0,
                marginLeft: 0,
              }}
              onClick={() => navigate(-1)}
            >
              <img src={Left} alt="" />{" "}
            </button>
            <img src={logo} alt="" />{" "}
          </div>
          <div className="user_profile">
            <div className="collapse navbar-collapse show">
              <ul className="navbar-nav d-flex     flex-row align-items-center">
                {user && !user.data.mainparentphone && <li className="nav-item">
                  <a href="" className="nav-link">
                    <img
                      src={Shop}
                      className="mr-3"
                      onClick={() => navigate("/evoOrder")}
                    />
                  </a>
                </li>
                }
                <Dropdown className="nav-item dropdown">
                  <Dropdown.Toggle>
                    <img src={Menu} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    aria-labelledby="navbarDropdownMenuLink "
                    className="dropdown-menu"
                  >
                    <Dropdown.Item
                      href="/profile"
                      class="dropdown-item"
                    >
                      Profile
                    </Dropdown.Item>
                    {user && !user.data.mainparentphone && <Dropdown.Item
                      href="/manageparents"
                      class="dropdown-item"
                    >
                      Manage Parents
                    </Dropdown.Item>
                    }
                    {user && !user.data.mainparentphone && <Dropdown.Item
                      href="/manageswimmers"
                      class="dropdown-item"
                    >
                      Manage Swimmers
                    </Dropdown.Item>
                    }
                    <Dropdown.Item
                      className="dropdown-item"
                      onClick={() => signOutUser()}
                    >
                      Logout
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      href="/swimmerParentFAQ"
                      class="dropdown-item"
                    >
                      Swimmer - Parent FAQ
                    </Dropdown.Item> */}

                    {/* <Dropdown.Item
                      ClassName="dropdown-item"
                      href="/swimClubFAQ"
                    >
                      Swim Club - FAQ
                    </Dropdown.Item> */}

                    {/*  <Dropdown.Item
                      ClassName="dropdown-item"
                      href="/manageSubscription"
                    >
                      Manage Subscription
                    </Dropdown.Item>
                    */}

                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
