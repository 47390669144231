import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function ManageSubscription() {
  return (
    <>
      <body>
        <Header />

        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title "> Manage Subscription</h1>
            <p className="mb_40 them_para them_para_2 text-center mx-auto  ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna Aliqua.{" "}
            </p>
            <div className="wraper_btn">
              <div className="btn_wraper mang_btn">
                <Link to="/manageCurrentSwimmersSubUn">
                  <button className="them_btn link">
                    Manage Current Swimmers
                  </button>
                </Link>
                <Link to="/cancelAllSubscriptions">
                  <button className="them_btn link">
                    Cancel All Subscriptions
                  </button>
                </Link>
                <Link to="/paymentHistory">
                  <button className="them_btn link">Payment History</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
