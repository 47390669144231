import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "./Header";
import { ToastContainer } from "react-toastify";

import GetStorage from "../services/Storage";
import http from "../services/http";
import { showToast } from "./ShowToast";
import OTPInput, { ResendOTP } from "otp-input-react";
import { SpinnerCircular } from "spinners-react";

export default function OtpLogs(props) {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [uploadSpin, setUploadSpin] = useState(false);
  const [error, setError] = useState("");

  const params = useParams();
  const { id, type } = params;

  const token = GetStorage.get("xAuthToken");

  const handleSubmit = async () => {
    getVideoToken();
  };
  const getVideoToken = async () => {
    setUploadSpin(true);
    http
      .post(`api/common/video_token`, {
        code: OTP,
        token: token,
      })
      .then((res) => {
        console.log("vide token------", res.data);
        // setUploadSpin(false);
        GetVideo(res.data.video_token);
      })
      .catch((error) => {
        setUploadSpin(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  }
  const GetVideo = async (video_token) => {
    http
      .post(`api/common/get_video?token=${token}`, {
        video_token: video_token,
        type: type,
        id: id,
      })
      .then((res) => {
        setUploadSpin(false);

        console.log("video url ------->>>>", res.data);
        localStorage.setItem("url", JSON.stringify(res.data));
        navigate("/logsVideo");
        console.log("navigate ------->>>>");
      })
      .catch((error) => {
        setUploadSpin(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <body>
        <Header />

        <section className="otp_verification custom_hight">
          <div className="login_inner  pt-0">
            <h1 className="mb_30 main_title ">OTP Verification </h1>
            <p className="mb_40 them_para text-center mx-auto">
              We have sent a 6 digit OTP to your phone number
              <br />
              Please enter the OTP to continue
            </p>

            <div className="otp_wraper">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                inputStyles={{ marginLeft: 0, marginRight: 8, width: 48, height: 48 }}
                inputClassName="otp_input"
              />
            </div>
            <div className="btn_wraper">
              <button
                type="submit"
                className="them_btn mt_40 mb_30"
                onClick={handleSubmit}
              >
                {uploadSpin ? (
                  <SpinnerCircular className="button-spin" />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </section>
        <ToastContainer />
      </body>
    </>
  );
}
