import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  swimmers: {},
};

const slice = createSlice({
  name: "swimmer",
  initialState,
  reducers: {
    setSwimmers: (state, action) => {
      state.swimmers = action.payload;
    },
    updateVideo: (state, action) => {
      state.selectVideo = action.payload;
    },
    resetSwimmer: (state, action) => {
      state = initialState;
    },
  },
});

export const { setSwimmers, updateUser, resetSwimmer } = slice.actions;
export default slice.reducer;
