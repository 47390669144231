import React from "react";
import Header from "./Header";

export default function WeAreSorryToSeeYouGo() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">We’re Sorry To See You Go</h1>
            <p className="mb_40 them_para text-center mx-auto">
              We have noticed that you cancelled the subscription for both of
              your children. Please select the reasons below to tell us why.{" "}
            </p>
            <form
              action="index_submit"
              method="get"
              className="custom_check_box custom_check_box_2"
            >
              <div className="form-group">
                <label className="check_box">
                  {" "}
                  Not Enough Filming
                  <input type="checkbox" checked="checked" />{" "}
                  <span className="checkmark"></span>{" "}
                </label>
              </div>
              <div className="form-group">
                <label className="check_box">
                  Can’t Afford The Service
                  <input type="checkbox" /> <span className="checkmark"></span>{" "}
                </label>
              </div>
              <div className="form-group">
                <label className="check_box">
                  My Swimmer Isn’t Serious
                  <input type="checkbox" /> <span className="checkmark"></span>{" "}
                </label>
              </div>
              <div className="form-group">
                <label className="check_box">
                  Other Personal Reasons
                  <input type="checkbox" /> <span className="checkmark"></span>{" "}
                </label>
              </div>
            </form>
            <div className="btn_wraper">
              <button type="button" className="them_btn mt_40 btn mb_30">
                Submit
              </button>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
