import axios from "axios";
import { apiPath } from "../configs";
import GetStorage from "./Storage";

const post = (
  url = "",
  body = {},
  headers = {},
  params = {},
  otherConfigs = {}
) => {
  return axios.post(`${apiPath}/${url}`, body, {
    headers,
    params,
    ...otherConfigs,
  });
};

const put = (url = "", body = {}, headers = {}, params = {}) => {
  return axios.put(`${apiPath}/${url}`, body, {
    headers,
    params,
  });
};

const get = (url = "", headers = {}, params = {}) => {
  return axios.get(`${apiPath}/${url}`, {
    headers,
    params,
  });
};

const deleteRequest = (url = "", headers = {}, params = {}) => {
  return axios.delete(`${apiPath}/${url}`, {
    headers,
    params,
  });
};
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 403) {
      GetStorage.remove("user");
      GetStorage.remove("xAuthToken");
      GetStorage.remove("xAuth");
      window.location.href = "/";
      alert("Login session is expired!");
    } else {
      return Promise.reject(err);
    }
  }
);

export default {
  get,
  post,
  put,
  delete: deleteRequest,
};
