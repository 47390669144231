import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { SpinnerCircular } from "spinners-react";
import { showToast } from "./ShowToast";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default function Profile() {

    const navigate = useNavigate();

    const token = GetStorage.get("xAuthToken");
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    const [firstname, setfirstName] = useState("");
    const [lastname, setLastname] = useState("");
    const [error, setError] = useState("");
    const [parent, setParent] = useState({});

    useEffect(() => {
        userGet();
    }, []);

    const userGet = () => {
        http
            .get(`api/parent/getCurrentParent/${user.data.id}?token=${token}`)
            .then((res) => {
                setParent(res.data);
                // setTeam_id(res.data.team_id);
                // setGroup_id(res.data.group_id);
                setfirstName(res.data.firstname);
                setLastname(res.data.lastname);
            })
            .catch((error) => {
                console.log("error ", error.response.data.errors);
            });
    };

    const onChangeProp = (event, key) => {
        parent[key] = event.target.value
        setParent(parent);
    };


    const onSubmit = async (values) => {
        console.log("value of submit button ===>", parent);

        http
            .put(`api/parent/editParent?token=${token}`, {
                id: parent.id,
                firstname: parent.firstname,
                lastname: parent.lastname,
                email: parent.email,
                gender: parent.gender,
                phone: parent.phone,
                //team_id: team_id,
            })
            .then((res) => {
                console.log("response", res.data);
                showToast("success", res.data.message);
                navigate(-1);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            setError(error.response.data.errors[key]);
                            showToast("error", error.response.data.errors[key]);
                        }
                    } else if (error.response.data.message) {
                        showToast("error", error.response.data.message);

                        setError(error.response.data.message);
                    }
                }
            });
    };

    return (
        <>
            <Header />
            <section className="otp_verification">
                <div className="custom_containers mb_40 over_flow">
                    <div id="content">
                        <Formik
                            initialValues={parent}
                            onSubmit={onSubmit}>
                            {({ values, handleSubmit, setFieldValue, handleChange }) => (
                                <>
                                    <div className="dashbord_content">
                                        <div className="dashobord_inner new_team_add">
                                            <h5 className="my-3">Parent Info</h5>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> First Name</label>
                                                            <input
                                                                type="text"
                                                                id="firstname"
                                                                name="firstname"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "firstname")
                                                                }}
                                                                value={parent.firstname}
                                                                className="custom_input form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Last Name</label>
                                                            <input
                                                                type="text"
                                                                id="lastname"
                                                                name="lastname"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "lastname")
                                                                }}
                                                                value={parent.lastname}
                                                                className="custom_input form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Gender</label>
                                                            <select
                                                                id="gender"
                                                                name="gender"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "gender")
                                                                }}
                                                                value={parent.gender}
                                                                className="form-control custom_input w-100"
                                                            >
                                                                <option
                                                                    value="MALE"
                                                                    selected={
                                                                        parent.gender === "MALE" ? true : false
                                                                    }
                                                                >
                                                                    Male
                                                                </option>
                                                                <option
                                                                    value="FEMALE"
                                                                    selected={
                                                                        parent.gender === "FEMALE" ? true : false
                                                                    }
                                                                >
                                                                    Female
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Email Address</label>
                                                            <input
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "email")
                                                                }}
                                                                value={parent.email}
                                                                className="custom_input form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <div className="fomr-group">
                                                            <label> Cell Number</label>
                                                            <input
                                                                type="text"
                                                                id="phone"
                                                                name="phone"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "phone")
                                                                }}
                                                                value={parent.phone}
                                                                className="custom_input form-control"
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="col-12 d-flex justify-content-end">
                                                        <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                                                            <button
                                                                onClick={() => navigate(-1)}
                                                                type="button"
                                                                className=" them_btn_ouline  "
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="them_btn "
                                                                onClick={onSubmit}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
            <ToastContainer />

        </>
    );
}
