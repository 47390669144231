import { setToken } from "../auth/authState";
import { setUser } from "../auth/user";
import http from "../../services/http";
import storage from "../../services/Storage";
import { showLoginLoader } from "../ui/loginUi";

export const login = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoginLoader(true));

      const res = await http.post("public/parent/auth", body);
      // console.log("response===>", res);
      const { data } = res;
      const { token } = data;
      let user = data;
      // console.log(user);
      dispatch(setToken(token));
      dispatch(setUser(data));
      storage.store("xAuthToken", token);
      storage.store("user", user);
      onSuccess(res);
      dispatch(showLoginLoader(false));
    } catch (error) {
      dispatch(showLoginLoader(false));

      // console.log("in error=====>", error.response.message);
      if (onError) onError(error);
    }
  };
};
