import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import http from "../services/http";
import GetStorage from "../services/Storage";
import { ToastContainer } from "react-toastify";
import { showToast } from "./ShowToast";
import { useDispatch, useSelector } from "react-redux";
import OTPInput, { ResendOTP } from "otp-input-react";
import { SpinnerCircular } from "spinners-react";
import { setResetToken } from "../store/auth/authState";

export default function VerifyYourEmail(props) {
  const emailForget = useSelector((state) => state.auth.emailForget);
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendCode, setresendCode] = useState("");

  const navigate = useNavigate();

  const ResendCode = () => {
    http
      .post(`public/parent/password/reset-request`, { phone: emailForget })
      .then((res) => {
        console.log("response==>", res.data);
        setresendCode(res.data.token);
        showToast("success", "code has been sent");
      });
  };

  useEffect(() => {
    const token = GetStorage.get("xAuthToken");
    setresendCode(token);
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    http
      .post(`public/parent/password/reset-code`, {
        code: OTP,
        token: resendCode,
      })
      .then((res) => {
        console.log("otp response ------->>>>", res.data.token);
        setLoading(false);
        dispatch(setResetToken(res.data.token));
        navigate("/resetpassword");
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              showToast("error", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            showToast("error", error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <>
        <body>
          <header>
            <div className="custom_container ">
              <div className="login_logo  mb_40">
                <button
                  style={{
                    backgroundColor: "transparent",
                    marginRight: 20,
                    borderWidth: 0,
                  }}
                  onClick={() => navigate(-1)}
                >
                  {/*     <img src={Left} alt="" />{" "} */}
                </button>
                {/*   <img src={logo} alt="" />{" "} */}
              </div>
            </div>
          </header>

          <section className="otp_verification custom_hight">
            <div className="login_inner  pt-0">
              <h1 className="mb_30 main_title ">OTP Verification </h1>
              <p className="mb_40 them_para text-center mx-auto">
                We have sent a 6 digit OTP to your phone number
                <br />
                Please enter the OTP to continue
              </p>

              <div className="otp_wraper" >
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  inputStyles={{ marginLeft: 0, marginRight: 8, width: 48, height: 48 }}
                  inputClassName="otp_input"
                />
              </div>
              <div className="btn_wraper">
                <button
                  type="submit"
                  className="them_btn mt_40 mb_30"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <SpinnerCircular className="button-spin" />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
              <div className="otp-resend">
                <p className="opt-receive-code">Didn't Receive Code?</p>
                <p className="otp-btn-link" onClick={ResendCode}>
                  {" "}
                  Resend Code
                </p>
              </div>
            </div>
          </section>
          <ToastContainer />
        </body>
      </>
    </>
  );
}
