import React from "react";
import Header from "./Header";

export default function SwimmerParentFAQ() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification  ">
          <div className="login_inner pt-0">
            <h1 className="mb_30 main_title ">Swimmer - Parent FAQ</h1>
            <div className="container Parent_FAQ">
              <p className="  them_para  w-100  text-left">
                Q: How does all this work?.{" "}
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Your club now has their own Argo SmartCam and will use it to
                film swimmers during practice. Each time your swimmer is filmed,
                the video is automatically uploaded to DropBox and you will get
                a text alert to view it.
              </p>
              <p className="  them_para  w-100  text-left">
                Q: Can I share the link in the text alert with friends and
                family so they can watch the video too?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Yes! Just send the Dropbox URL to anyone you want to see the
                video. You can also download and share the video without
                restriction from your phone, tablet or desktop.{" "}
              </p>
              <p className="  them_para  w-100  text-left">
                Q: How long after my swimmer is filmed until the video can be
                viewed?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Video is generally uploaded once per day; the same day it is
                taken. Think “uploading all night long after practice ends”.
                Upload speeds vary widely depending on bandwidth. Video is
                usually ready by the following morning but not always.
              </p>
              <p className="  them_para  w-100  text-left">
                Q: How often will my swimmer be filmed?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Your coach will decide based on: lesson plan, lane space,
                length of practice, coaches on deck, attendance, etc. The
                frequency of filming varies a great deal. In other words,
                everyone has a different theory on what works best.
              </p>
              <p className="  them_para  w-100  text-left">
                Q: What things will be filmed?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: That’s completely up to your team and coach. Some teams film
                drills only, while other teams film full end-to-end strokes and
                turns. Many teams film both.
              </p>
              <p className="  them_para  w-100  text-left">
                Q: Will my swimmer be filmed at swim meets?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: No, filming will only be done at practice and sometimes on
                special filming days
              </p>
              <p className="  them_para  w-100  text-left">
                Q: My kid swims long course. Will filming continue during the
                May-Aug long course season?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: If you keep making your subscription, your swimmer keeps
                getting filmed and reviewed.
              </p>
              <p className="  them_para  w-100  text-left">
                Q: What about review of the video?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Your coach will use the video as part of his/her balanced
                video-teaching plan. It’s their call as to when, where and in
                what manner to conduct review of your swimmer’s video. Some
                coaches film and review in a micro-teaching way during most
                practices. Some teams designate a certain day for a certain
                group to film and analyze. Some teams prefer to have a “filming
                day”; typically on the weekend.{" "}
              </p>
              <p className="  them_para  w-100  text-left">
                Q: I’ve heard that Argo offers voice over analytics? How do we
                get this?
              </p>
              <p className="mb_30  them_para w-100  text-left">
                A: Yes, Argo has assembled an all-star team of advanced
                age-group and collegiate coaches who do our educational
                voice-over work part-time, from home, in an entirely digital
                way. We currently offer a one-stroke analysis, delivered within
                a week, running 12 minutes long, covering 5 essential,
                fundamental teaching-points, delivered digitally to your DropBox
                swimmer locker. Cost is $25. View sample by clicking{" "}
                <a href="" className="text-dark">
                  <u>here</u>
                </a>
                .{" "}
              </p>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
