import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import polygon from "../assets/images/Polygon 56.png";
import Dropdown from "react-bootstrap/Dropdown";
import dots from "../assets/images/dots.svg";
import http from "../services/http";
import GetStorage from "../services/Storage";
import { Table } from 'antd'

export default function CheckOut() {
  const packagesID = JSON.parse(localStorage.getItem("packagesID"));
  const packages = [packagesID];
  const navigate = useNavigate();
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  useEffect(() => {
    localStorage.removeItem("viewed");
    localStorage.removeItem("url");

  }, []);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'sample',
      key: 'sample',
      render: (_, record) => (
        <Link to={"#"} onClick={() =>
          WatchVideoCode("package")} >
          {" "}
          {
            record.sample.split("/")[
            record.sample.split("/").length - 1
            ]
          }
        </Link>

      ),
      sorter: (a, b) => a.sample.length - b.sample.length,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <label>  {" "}
          {
            record.name
          }
        </label>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Delivery Time',
      dataIndex: 'duration',
      key: 'duration',
      render: (_, record) => (
        <div>
          {" "}
          {
            `Delivered Within ${record.duration} Weeks`
          }
        </div>
      ),
      sorter: (a, b) => a.duration.length - b.duration.length,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => (
        <div>
          {" "}
          ${record.price}
        </div>
      ),
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, item) => (
        <table>
          <td>
            <div className="btn_wraper d-flex justify-content-left">
              <button
                type="button"
                className="them_btn "
                onClick={() => WatchVideoCode("package")}
              >
                Show video
              </button>
            </div>

          </td>

        </table>
      ),
    },
  ]
  const WatchVideoCode = async (type) => {
    console.log("click me");
    // setLoading(true);

    http
      .get(`api/common/video_request?token=${token}`)
      .then((res) => {
        console.log("response ------->>>>", res.data);

        navigate(`/otpLogs/${packagesID.id}/${type}`);

        // setLocker(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  return (
    <>
      <body>
        <Header />
        <section class="otp_verification">
          <div class="login_inner custom_hight pt-0">
            <h1 class="mb_30 main_title ">Checkout</h1>
            <div class="table_vedio_upload  container mt_40 mb_30 over_flow mb-0">
              <p class="mb_40 them_para text-left heading ">Order(s) Details</p>
              <Table columns={columns} dataSource={packages}></Table>
              {/* <table class="table table-borderless table-responsive-lg">
                <thead>
                  <tr>
                    
                    <th scope="col" class=" " style={{ paddingLeft: 20 }}>
                      Name
                    </th>
                    <th scope="col" class=" ">
                      eVO Package
                    </th>
                    <th scope="col" class=" ">
                      Delivery Time
                    </th>
                    <th scope="col" class="text-center">
                      Price
                    </th>
                    <th scope="col" class="  pr_80 text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    
                    <td style={{ paddingLeft: 20 }}>
                      {
                        packagesID.sample.split("/")[
                        packagesID.sample.split("/").length - 1
                        ]
                      }
                    </td>
                    <td>{packagesID.name}</td>
                    <td>Delivered Within {packagesID.duration} Weeks</td>
                    <td>
                      <div class="btn_wraper d-flex justify-content-center  ">
                        {" "}
                        ${packagesID.price}
                      </div>
                    </td>
                    <td>
                      <div className="btn_wraper d-flex justify-content-center">
                        <button
                          type="button"
                          className="them_btn "
                          onClick={() => WatchVideoCode("package")}
                        >
                          Show video
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </div>
            <div class="btn_wraper">
              <Link to="/stripePayment">
                <button type="button" class="them_btn_ouline mt_40 mb_30">
                  Checkout
                </button>
              </Link>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
