import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Table, Select, DatePicker, Button, Space, Spin, Modal, Typography, Dropdown as AntdDropdown, List, Card, Row, Col } from 'antd'
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { SpinnerCircular } from "spinners-react";
import { showToast } from "./ShowToast";
import GetStorage from "../services/Storage";
import http from "../services/http";
import { ToastContainer } from "react-toastify";
import dropdown from "../assets/images/dropdown.png";
import moment from "moment";
import { useRef } from "react";

export default function AddSwimmer() {
    const location = useLocation();
    const formref = useRef();

    const [loading, setLoading] = useState(false);
    const [teamGroups, setTeamGroups] = useState([]);
    const [teams, setTeams] = useState([]);

    const [swimmer, setSwimmer] = useState({
        firstname: "",
        lastname: "",
        gender: "MALE",
        group_id: "",
        age: "",
        birthday: null,
        team_id: "",
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();
    const token = GetStorage.get("xAuthToken");


    const initialValues = {
        firstname: "",
        lastname: "",
        gender: "MALE",
        group_id: "",
        age: "",
        birthday: null,
        team_id: "",
    };


    const validationSchema = Yup.object({
        firstname: Yup.string().required("FirstName is Required."),
        lastname: Yup.string().required("LastName is Required."),
        gender: Yup.string().required("Gender is Required."),
        age: Yup.string().required("Age is Required."),
        group_id: Yup.string().required("Group is required."),
        team_id: Yup.string().required("Team is required."),
    });

    useEffect(() => {
        loadTeam()
    }, []);

    const loadTeam = () => {
        http
            .get(`public/parent/allteam`)
            .then((res) => {
                console.log("response----->", res.data);
                setTeams(res.data);

                // setLoading(false);
            })
            .catch((error) => {
                // setLoading(false);
            });
    };

    useEffect(() => {
        loadTeamGroup()
        if (location.state && location.state.swimmer_id) {
            getSwimmer();
        }
    }, []);


    const getSwimmer = () => {
        http
            .get(`api/parent/getswimmer/${location.state.swimmer_id}?token=${token}`)
            .then((res) => {
                setSwimmer(res.data);
                formref.current.setValues(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            setError(error.response.data.errors[key]);
                            showToast("error", error.response.data.errors[key]);
                        }
                    } else if (error.response.data.message) {
                        showToast("error", error.response.data.message);

                        setError(error.response.data.message);
                    }
                }
            });
    };

    const onSubmits = async (values, { resetForm }) => {

        if (swimmer.id) {

            http
                .put(`api/parent/editswimmer/${swimmer.id}?token=${token}`, values)
                .then((res) => {
                    showToast("success", res.data.message);
                    navigate("/welcomejessica")
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            for (let key in error.response.data.errors) {
                                setError(error.response.data.errors[key]);
                                showToast("error", error.response.data.errors[key]);
                            }
                        } else if (error.response.data.message) {
                            showToast("error", error.response.data.message);

                            setError(error.response.data.message);
                        }
                    }
                });
        } else {

            http
                .post(`api/parent/addswimmer?token=${token}`, values)
                .then((res) => {
                    showToast("success", res.data.message);
                    navigate("/welcomejessica")
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            for (let key in error.response.data.errors) {
                                setError(error.response.data.errors[key]);
                                showToast("error", error.response.data.errors[key]);
                            }
                        } else if (error.response.data.message) {
                            showToast("error", error.response.data.message);

                            setError(error.response.data.message);
                        }
                    }
                });
        }



    };

    const loadTeamGroup = () => {
        http
            .get(`api/parent/teamgroup/byparentteam/?token=${token}`)
            .then((res) => {
                setTeamGroups(res.data);
            })
            .catch((error) => {
            });
    }

    const onChangeProp = (event, key) => {
        swimmer[key] = event.target.value
        setSwimmer(swimmer);
    };
    return (
        <>


            <Header />
            <section className="otp_verification">
                <div className="custom_containers mb_40 over_flow">
                    <Formik innerRef={formref}
                        initialValues={swimmer}
                        validationSchema={validationSchema}
                        onSubmit={onSubmits}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <>
                                <div className="dashbord_content">
                                    <div className="dashobord_inner new_team_add">
                                        <h5 className="my-3">New swimmer info</h5>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> First Name</label>
                                                        <input
                                                            type="text"
                                                            id="firstname"
                                                            name="firstname"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onChangeProp(e, "firstname")
                                                            }}
                                                            value={swimmer.firstname}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="firstname" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Last Name</label>
                                                        <input
                                                            type="text"
                                                            id="lastname"
                                                            name="lastname"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onChangeProp(e, "lastname")
                                                            }}
                                                            value={swimmer.lastname}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="lastname" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Gender</label>
                                                        <div class=" icon mx-0 Edit_Setting">
                                                            <select
                                                                id="gender"
                                                                name="gender"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "gender")
                                                                }}
                                                                value={swimmer.gender}
                                                                className="form-control custom_input w-100"
                                                            >
                                                                <option
                                                                    value="MALE"
                                                                >
                                                                    Male
                                                                </option>
                                                                <option
                                                                    value="FEMALE"
                                                                >
                                                                    Female
                                                                </option>
                                                            </select>
                                                            <div className="img_icon">
                                                                <img src={dropdown} alt="" />{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Age</label>
                                                        <input
                                                            type="number"
                                                            id="age"
                                                            name="age"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onChangeProp(e, "age")
                                                            }}
                                                            value={swimmer.age}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="age" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Date of Birth</label>
                                                        <input
                                                            type="date"
                                                            id="birthday"
                                                            name="birthday"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onChangeProp(e, "birthday")
                                                            }}
                                                            value={moment(swimmer.birthday).format("yyyy-MM-DD")}
                                                            className="custom_input form-control"
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="birthday" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Select team</label>
                                                        <div class=" icon mx-0 Edit_Setting">
                                                            <select
                                                                id="team_id"
                                                                name="team_id"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "team_id")
                                                                }}
                                                                value={swimmer.team_id}
                                                                className="form-control custom_input"
                                                            >
                                                                <option value="" selected>
                                                                    --- Select team ---
                                                                </option>
                                                                {teams?.map((item, i) => (
                                                                    <option key={i} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div className="img_icon">
                                                                <img src={dropdown} alt="" />{" "}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="team_id" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="fomr-group">
                                                        <label> Select Group</label>
                                                        <div class=" icon mx-0 Edit_Setting">
                                                            <select
                                                                id="group_id"
                                                                name="group_id"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    onChangeProp(e, "group_id")
                                                                }}
                                                                value={swimmer.group_id}
                                                                className="form-control custom_input w-100"
                                                            >
                                                                <option value="" selected>
                                                                    --- Select group ---
                                                                </option>
                                                                <option value="0" selected>
                                                                    Not Assigned
                                                                </option>
                                                                {teamGroups?.map((item, i) => (
                                                                    <option key={i} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div className="img_icon">
                                                                <img src={dropdown} alt="" />{" "}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            <ErrorMessage name="group_id" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-end">
                                                    <div className="btn_wraper d-flex Permissions_text align-item-center mt_40 mb_30">
                                                        <Link to="/welcomeJessica">
                                                            <button
                                                                type="button"
                                                                className=" them_btn_ouline  "
                                                            >
                                                                Cancel
                                                            </button>
                                                        </Link>

                                                        <button
                                                            type="button"
                                                            to="/welcomeJessica"
                                                            onClick={handleSubmit}
                                                            className=" them_btn  "
                                                        >
                                                            {loading ? (
                                                                <SpinnerCircular
                                                                    style={{ height: "40", marginTop: "7" }}
                                                                />
                                                            ) : (
                                                                "  Save"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </section>
            <ToastContainer />
        </>
    );
}
