import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import http from "../services/http";
import visable from "../assets/images/visibility.png";
import unvisable from "../assets/images/unvisibility.png";
import { showToast } from "./ShowToast";

export default function Resetpassword() {
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const resetToken = useSelector((state) => state.auth.resetToken);

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("required")
      .min(5, "Password is too short - should be 5 chars minimum."),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    http
      .put(`api/parent/reset-password?token=${resetToken.token}`, {
        password: values.password,
      })
      .then((res) => {
        console.log("response in ===>", res.data);
        // showToast("success", res.data.message);
        navigate("/");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
              console.log("error---->", error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
            console.log("error1---->", error.response.data.message);
          }
        }
      });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <>
            <section className="login_wraper">
              <div className="login_inner">
                <div className="login_logo mb_40">
                  {" "}
                  <img src={Logo} alt="" />{" "}
                </div>
                <h1 className="mb_30 main_title">Reset Password</h1>
                <p className="mb_40 them_para">
                  Please enter the strong password to update your old password.
                </p>
                <form action="index_submit" method="get">
                  <div class="form-group icon">
                    <input
                      name="password"
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                      type={passwordType}
                      className="custom_input form-control"
                      placeholder="Password"
                    />
                    <div class="img_icon">
                      {passwordType === "password" ? (
                        <a>
                          <img src={visable} alt="" onClick={togglePassword} />
                        </a>
                      ) : (
                        <a>
                          <img
                            src={unvisable}
                            alt=""
                            onClick={togglePassword}
                          />{" "}
                        </a>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      color: "red",
                    }}
                  >
                    <ErrorMessage name="password" />
                  </div>
                  <div style={{ padding: 2, marginLeft: 40 }}>
                    <h6 style={{ color: "red", alignSelf: "center" }}>
                      {error}
                    </h6>
                  </div>
                  <div className="btn_wraper">
                    <button
                      type="submit"
                      className="them_btn mt_40 mb_30"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <SpinnerCircular className="button-spin" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </>
        )}
      </Formik>
    </>
  );
}
