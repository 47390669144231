import React from "react";
import Header from "./Header";
import Dropdown from "../assets/images/dropdown.png";
import ReactDOM from "react-dom";

export default function SelectGroupChild() {
  return (
    <>
      <Header />
      <section className="otp_verification   custom_hight ">
        <div className="login_inner">
          <h1 className="mb_30 main_title ">Select a group</h1>
          <p className="mb_40 them_para text-center mx-auto  ">
            Please select your children's group in order to proceed.
          </p>

          <form action="index_submit" className="Edit_Setting">
            <div className="form-group icon mt-3">
              <select name="" className="form-control custom_input">
                <option value="">Group</option>
                <option value="">Groups</option>
              </select>
              <div className="img_icon">
                {" "}
                <img src={Dropdown} alt="" />{" "}
              </div>
            </div>
            <div className="form-group icon mt-3">
              <select
                className="js-example-basic-multiple form-control"
                name="states[]"
                multiple="multiple"
              >
                <option value="AL">Alabama</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </form>
          <div className="btn_wraper">
            <button type="button" className="them_btn mt_40 mb_30">
              Select
            </button>
          </div>
          <p className="them_para mx-auto  mt_40">
            Don't Have An Account?
            <a href="" className="btn-link  ">
              {" "}
              Register Now
            </a>
          </p>
        </div>
      </section>

      <body>
        {/* <script
          type="application/javascript"
          src="assets/js/jquery.min.js"
        ></script>
        <script
          type="application/javascript"
          src="assets/js/bootstrap.bundle.min.js"
        ></script>

        <script
          type="application/javascript"
          src="https://cdn.jsdelivr.net/npm/select2@4.0.13/dist/js/select2.min.js"
        ></script> */}

        {/* <script>
          $(document).ready(function(){" "}
          {$(".js-example-basic-multiple").select2()});
        </script> */}
      </body>
    </>
  );
}
