//PROD
export const baseUrl = "https://argo-backend-api.herokuapp.com";

//DEV
//export const baseUrl = "https://argo-backend-api-dev.herokuapp.com";


// export const baseUrl = "http://192.168.1.5:5001";

export const apiPath = baseUrl;
