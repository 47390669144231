import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Left from "../assets/images/left.png";
import logo from "../assets/images/logo.png";
import Header from "./Header";

export default function CnfirmIdentity() {
  const navigate = useNavigate();

  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner">
            <h1 className="mb_30 main_title ">Confirm your identity</h1>
            <p className="mb_40 them_para text-center mx-auto">
              Enter your cell number... Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt{" "}
            </p>
            <form action="index_submit" method="get">
              <div className="form-group">
                <input
                  type="text"
                  name=""
                  className="custom_input form-controlident"
                  value=""
                  placeholder="Cell Number"
                />{" "}
              </div>
            </form>
            <div className="btn_wraper">
              <Link to="/otpNumber">
                <button type="button" className="them_btn mt_40 mb_30">
                  Verify
                </button>
              </Link>
            </div>
            <p className="them_para mx-auto  mt_40">
              {" "}
              <a href="" className="btn-link  ">
                {" "}
                Don't have access to your cell phone?
              </a>
            </p>
          </div>
        </section>
      </body>
    </>
  );
}
