import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import GetStorage from "../services/Storage";
import http from "../services/http";
import Header from "./Header";
import CheckoutForm from "./CheckoutForm";
import "../assets/stripe.css";
import { SpinnerCircular } from "spinners-react";

const stripePromise = loadStripe("pk_test_ZJK8mVUSPN50KPKCXfQG7jAM");

export default function StripePayment() {
  const packagesID = JSON.parse(localStorage.getItem("packagesID"));

  const [clientSecret, setClientSecret] = useState("");
  const token = GetStorage.get("xAuthToken");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    paymentmethod();
  }, []);

  const paymentmethod = async () => {
    setLoading(true);

    http
      .post(`api/parent/payment-intent?token=${token}`, {
        package_id: packagesID.id,
      })
      .then((res) => {
        setLoading(false);

        setClientSecret(res.data.client_secret);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          if (error.response.data.errors) {
            for (let key in error.response.data.errors) {
              setError(error.response.data.errors[key]);
            }
          } else if (error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      });
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      style={{ backgroundColor: "#ddd", height: "100vh", minHeight: "100vh" }}
    >
      <Header />
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SpinnerCircular size="7%" />
        </div>
      ) : (
        <div id="payment">
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )}
        </div>
      )}
    </div>
  );
}
