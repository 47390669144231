import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function PaymentProcessStripe() {
  return (
    <>
      <body>
        <Header />
        <section className="otp_verification custom_hight">
          <div className="login_inner pt-0">
            <Link to="/thankYouForYourOrder">
              <h1 className="mb_30 main_title ">
                Payment Process Using Stripe
              </h1>
            </Link>{" "}
          </div>
        </section>
      </body>
    </>
  );
}
